import { useStateContext } from './useStateContext';
import { useMemo } from 'react';

const NEVER_DISABLED = ['advice', 'requestCallbackEnd'];

export const useWidgetIsDisabled = () => {
	const [{ conversation, sectionIndex, currentSection }] = useStateContext();

	const triageIsDisabled = useMemo(() => (
		conversation.some(step => ['consultationPreparation', 'requestCallback'].includes(step.type))
	), [conversation.length]);
	const contactRequestIsDisabled = useMemo(() => (
		conversation.some(step => step.type === 'callbackRequestScheduler')
	), [conversation.length]);
	const schedulerIsDisabled = useMemo(() => (
		conversation.some(step => step.type === 'requestCallbackEnd')
	), [conversation.length]);
	const hasConsultationPreparation = useMemo(() => (
		conversation.some(step => step.type === 'consultationPreparation')
	), [conversation.length]);

	const sectionIsDisabled = useMemo(() => {
		if (NEVER_DISABLED.includes(currentSection?.type ?? '')) {
			return false;
		}

		if (schedulerIsDisabled) {
			return true;
		}

		if (contactRequestIsDisabled) {
			return sectionIndex <= conversation.findIndex(step => step.type === 'requestCallback');
		}

		const pastAdvice = hasConsultationPreparation || conversation.some(step => step.type === 'requestCallback');

		if (!pastAdvice) {
			return false;
		}

		return triageIsDisabled && sectionIndex < conversation.findIndex(step => ['consultationPreparation', 'requestCallback'].includes(step.type));
	}, [currentSection?.type, sectionIndex, triageIsDisabled, contactRequestIsDisabled, schedulerIsDisabled, hasConsultationPreparation]);

	return {
		triageIsDisabled,
		contactRequestIsDisabled,
		schedulerIsDisabled,
		sectionIsDisabled
	};
};
