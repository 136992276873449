// general styles which can be used by various componenents

import React from 'react';
import styled from 'styled-components';
import type { ThemeFontSizes, ThemeType } from '../../models';
import type { DivProps } from '../../models/widget/Styled';

const getFontSize = (heading: string, fontSizes: ThemeFontSizes) => {
	switch (heading) {
		case 'h1':
			return fontSizes.xl5;
		case 'h2':
			return fontSizes.xl3;
		case 'h3':
			return fontSizes.xl2;
		case 'h4':
			return fontSizes.xl;
		case 'h6':
			return fontSizes.xs;
		case 'h5':
		default:
			return fontSizes.base;
	}
};

const ActivityHeader = styled.div`
	&&& {
		position: relative;
		display: flex;
		flex-direction: row;
		margin-bottom: 15px;

		h2 {
			font-weight: bold !important;
			padding-left: 10px;
			align-self: center;
		}
		@media ${(props) => props.theme.screens.medium} {
			margin-bottom: 24px;
		}
	}
`;

const LineDivider = styled.label`
	width: 100%;
	display: flex;
	height: 2px;
	background-color: ${(props) => props.theme.colors.accent100};
	margin: 15px 0px;
`;

const Activity = styled.div`
	&&& {
		position: relative;

		/* &:not(:first-child) {
			margin-top: 30px;
		}

		p,
		ul {
			&[data-texttype='markdown'] {
				margin-bottom: 5px;
			}
		}
		ul ul {
			&[data-texttype='markdown'] {
				margin-bottom: 0;
			}
		} */
	}
`;

const ActivityBack = styled.button`
	&&& {
		all: unset;
		display: flex;
		position: absolute;
		top: 0px;
		inset-inline-start: 0;
		cursor: pointer;
		background: #fff;
		width: 34px;
		height: 34px;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.15);
		z-index: 1;

		&:focus,
		&:hover {
			background: ${(props) => props.theme.colors.accent};
			color: #fff;
			outline: none;

			svg {
				transform: rotate(90deg);

				path {
					fill: #fff;
				}
			}
		}

		svg {
			transition: transform 0.15s ease-out;
			path {
				fill: ${(props) => props.theme.colors.accent};
			}
		}
	}
`;

interface ActivityGroupProps {
	$modalClosed?: boolean;
	$narrow?: boolean;
	$showWelcomeText?: boolean;
}
const ActivityGroup = styled.div<ActivityGroupProps>`
	${(props) =>
		!props.$narrow &&
		props.$modalClosed &&
		props.$showWelcomeText &&
		`
		display: grid;
		grid-template-columns: 1fr 1fr;
		gap: ${props.theme.spacings.medium};
	`};
`;

export interface ActivityBubbleProps {
	$group?: boolean;
	$modalOpen?: boolean;
}

const ActivityBubble = styled.div.attrs<ActivityBubbleProps>((props) => ({
	role: props.$group ? 'group' : undefined
}))<ActivityBubbleProps>`
	&&& {
		background: #fff;
		border-radius: ${(props) => props.theme.radii.default};
		color: ${(props) => props.theme.colors.text};
		padding: ${(props) => (props.$modalOpen === false ? '15px' : '')};
		max-height: initial;
		text-align: start;
		box-sizing: border-box;
		position: relative;
		margin-bottom: ${(props) => props.theme.spacings.medium};
		scroll-behavior: smooth;

		@media ${(props) => props.theme.screens.medium} {
			max-height: unset;
			padding: 30px;
			border: solid 1px #0000000d;
		}
	}
`;

type EmpathyBubbleStyle = 'success' | 'danger' | 'warning';

interface EmpathyBubbleProps {
	$style?: EmpathyBubbleStyle;
}

const getEmpathyBackground = (theme: ThemeType, style?: EmpathyBubbleStyle) => {
	switch (style) {
		case 'success':
			return theme.colors.noticeSuccess;
		case 'danger':
			return theme.colors.noticeDanger;
		default:
			return theme.colors.noticeDefault;
	}
}

const EmpathyBubble = styled.div<EmpathyBubbleProps>`
	border-radius: ${(props) => props.theme.radii.default};
	background-color: ${(props) => getEmpathyBackground(props.theme, props.$style)};
	padding: ${(props) => `${props.theme.spacings.medium} ${props.theme.spacings.medium}`};
	margin-bottom: ${(props) => props.theme.spacings.large};
	color: ${(props) => props.theme.colors.text900};
`;

const BubbleTitle = styled.h2`
	&&& {
		font-size: ${(props) => props.theme.fontSizes.base};
		font-weight: 400;
		line-height: ${(props) => props.theme.spacings.large};
		margin: 0;
		align-self: flex-start;
		align-items: baseline;

		span {
			width: unset;
			max-width: unset;
			text-align: unset;
			margin-bottom: 0;
		}

		[data-texttype='markdown'] {
			width: unset;
			max-width: unset;
			text-align: unset;
			margin-bottom: 0;

			strong {
				font-size: 1.15em;
				font-weight: 600;
			}
		}

		&:focus-visible {
			outline: none;
		}
		&.text-centered {
			text-align: center;
		}
		&.full-width {
			width: 100%;
		}
	}
`;

interface BubbleSubTitleProps {
	$heading?: string;
}

const BubbleSubTitle = styled.div<BubbleSubTitleProps>`
	&&& {
		color: ${(props) => props.theme.colors.text900};
		margin-bottom: 5px;
		font-size: ${(props) => getFontSize(props.$heading || '', props.theme.fontSizes)};
		font-weight: 600;
		line-height: 1.5;
		[data-texttype='markdown'] {
			margin-bottom: 0;
			font-weight: 300;

			strong {
				font-size: 1.05em;
				font-weight: 600;
			}
		}
	}
`;

export interface ActivityActionsProps {
	$widgetIsNarrow?: boolean;
}

const ActivityActions = styled.div<ActivityActionsProps>`
	margin: ${(props) => props.theme.spacings.medium} 0;
	display: flex;
`;

const ComplaintsList = styled.ul`
	list-style: none;
	column-count: 1;
	@media ${(props) => props.theme.screens.medium} {
		column-count: 2;
	}
`;

const Complaint = styled.li`
	margin-bottom: ${(props) => props.theme.spacings.extraSmall};
	display: inline-block;
	width: 100%;
`;

interface ComplaintButtonProps {
	$selected?: boolean;
}

const NewComplaintButton = styled.button.attrs<ComplaintButtonProps>((props) => ({
	'aria-pressed': props.$selected
}))<ComplaintButtonProps>`
	&&& {
		width: 100%;
		height: 48px;
		cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
		padding: 0 ${(props) => props.theme.spacings.medium};
		border: ${(props) => (props.$selected ? `solid 2px ${props.theme.colors.accent}` : `solid 2px ${props.theme.colors.text200}`)};
		border-radius: ${(props) => props.theme.radii.default};
		background-color: ${(props) => (props.$selected ? props.theme.colors.accent100 : props.theme.colors.text100)};
		color: ${(props) => props.theme.colors.text700};
		text-align: left;
		font-weight: 400;
		outline-color: ${(props) => props.theme.colors.accent};

		& span:first-child {
			display: inline-block;
			&:first-letter {
				text-transform: capitalize;
			}
		}

		&:hover {
			border-color: ${(props) => props.$selected ? props.theme.colors.accent : props.theme.colors.text300};
		}

		&:focus,
		&:active {
			outline: solid 1px ${(props) => props.theme.colors.accent};
		}
	}
`;

const ComplaintButton = styled.button.attrs<ComplaintButtonProps>((props) => ({
	'aria-pressed': props.$selected
}))<ComplaintButtonProps>`
	&&& {
		all: unset;

		// or maybe simply use different theme variables instead of extra calc
		width: calc(100% - ${(props) => `calc(0.7 * ${props.theme.spacings.medium})`});
		padding: ${(props) => props.theme.spacings.extraSmall} ${(props) => `calc(0.35 * ${props.theme.spacings.medium})`};

		cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
		background: ${(props) => (props.$selected ? props.theme.colors.accent : 'transparent')};
		color: ${(props) => (props.$selected ? props.theme.colors.textinverted : props.disabled ? props.theme.colors.text : props.theme.colors.accent)};

		&:hover {
			background-color: ${(props) => !props.disabled && props.theme.colors.accent600};
			color: ${(props) => (!props.disabled || props.$selected ? props.theme.colors.textinverted : props.theme.colors.text)};
		}

		&:focus {
			outline: 2px auto ${(props) => props.theme.colors.accent600};
		}
	}
`;

const VisuallyHidden = styled.div`
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px;
`;

const MarkDownUl = styled.ul`
	list-style: disc;
	padding-inline-start: ${(props) => props.theme.spacings.large};
`;

const MarkDownLi = styled.li`
	padding-inline-start: 0;
	list-style: disc;
`;

const MarkdownImage = styled.img`
	display: block;
	max-width: 100%;
	max-height: 300px;
	object-fit: contain;
`;

const MarkDownLink = styled.a`
	color: ${(props) => props.theme.colors.accent};
	text-decoration: underline;
	display: inline-flex;
	flex-direction: row;
	gap: ${(props) => props.theme.spacings.small};
	align-items: center;

	&:hover {
		color: ${(props) => props.theme.colors.accent700};
	}
`;

const MarkDownP = styled.p`
	font-size: ${(props) => props.theme.fontSizes.base};
	color: ${(props) => props.theme.colors.text700};
`;

interface ChipProps {
	$active?: boolean;
	$square?: boolean;
}

const ChipsWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${(props) => props.theme.spacings.small};
	background-color: white;
	margin: 0 -${(props) => props.theme.spacings.medium};
	padding: ${(props) => props.theme.spacings.medium} 0;
	max-width: 100%;
	flex-wrap: wrap;
`;

const Chip = styled.div<ChipProps>`
	cursor: pointer;
	border: solid 1px ${(props) => props.theme.colors.borderLight};
	border-radius: ${(props) => (props.$square ? '5px' : props.theme.spacings.large)};
	padding: ${(props) => `${props.theme.spacings.extraSmall} ${props.theme.spacings.small}`};
	background-color: ${(props) => (props.$active ? '#0000001A' : '#fff')};
	display: flex;
	flex-direction: row;
	gap: ${(props) => props.theme.spacings.small};
	align-items: center;
`;

const S = {
	ActivityHeader,
	LineDivider,
	Activity,
	ActivityBack,
	ActivityGroup,
	ActivityBubble,
	EmpathyBubble,
	BubbleTitle,
	BubbleSubTitle,
	ActivityActions: (props: DivProps<ActivityActionsProps>) => <ActivityActions {...props}>{props.children}</ActivityActions>,
	ComplaintsList,
	Complaint,
	NewComplaintButton,
	ComplaintButton,
	VisuallyHidden,
	MarkDownUl,
	MarkDownLi,
	MarkdownImage,
	MarkDownP,
	MarkDownLink,
	ChipsWrapper,
	Chip
};

export default S;
