import React, { useState, useCallback, FunctionComponent, useMemo } from 'react';
import { DateTime } from 'luxon';
import { default as S } from './styled';
import { default as SA } from '../Activity/Activity.styled';
import { default as SAA } from '../ActivityAdvice/ActivityAdvice.styled';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import SurveyIntro from '../SurveyIntro/SurveyIntro';
import EndButtons from '../EndButtons/EndButtons';
import { faClock } from '@fortawesome/free-regular-svg-icons';
import { faAlignJustify, faCalendarDay, faCheck, faPhone, faXmark } from '@fortawesome/free-solid-svg-icons';
import { fullDate, timeInterval, timeRange } from '../../helpers/support/dateTimeHelpers';
import type { ActivityAnswer, ActivityAnswerComplaintSelector, ActivityAnswerSurveyQuestion, ActivityStepAdvice, Advice, TimeSlot } from '../../models';
import ExpandableDiv from '../ExpandableDiv/ExpandableDiv';
import Feedback from '../AdviceFeedback/Feedback';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { ActivityProps } from '../Activity';
import ConfirmationImage from '../../assets/Confirmation.svg';
import { useTheme } from 'styled-components';
import { useWindowSize } from '../../helpers/hooks/useWindowSize';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';

interface ProcedureContactRequestEndProps extends ActivityProps {
	callbackTime?: string;
	urgence: string;
	timeSlot?: TimeSlot | null;
	phoneNumber?: string | null;
	useFirstAvailableTimeSlot?: boolean | null;
	isLastActivity: boolean;
	modalOpen?: boolean;
	answer?: Omit<ActivityAnswer, 'undefined'>;
}

const ProcedureContactRequestEnd: FunctionComponent<ProcedureContactRequestEndProps> = (props) => {
	const [{ settings, conversation, profile }] = useStateContext();
	const [showFollowupRestart, setShowFollowupRestart] = useState(true);
	const { callbackTime, urgence, timeSlot, phoneNumber, useFirstAvailableTimeSlot } = props;
	const [advice] = useState((conversation.find((a) => a.type === 'advice') as ActivityStepAdvice)?.advice);
	const themeContext = useTheme();
	const isMobile = useWindowSize().width < themeContext.breakpoints.medium;
	const toggleModal = useToggleModal();

	const callbackDateTime = useMemo(() => {
		return callbackTime
			? DateTime.fromISO(callbackTime)
			: undefined;
	}, [callbackTime]);

	const focusLastActivity = useCallback(
		(node: HTMLElement | null) => {
			if (node && props.isLastActivity && props.modalOpen) {
				node.focus();
			}
		},
		[props.isLastActivity, props.modalOpen]
	);

	const handleSurveyAction = async (answer: ActivityAnswerSurveyQuestion) => {
		setShowFollowupRestart(false);
		return props.handleActivityResponse({ ...props.answer, surveyAnswerId: answer.answerId, surveyAnswerText: answer.answerText });
	};

	const handleActivityEndActions = (answer: object) => {
		switch ((answer as { message: string }).message) {
			case 'feedback':
				void props.handleActivityResponse({ ...props.answer, isFeedbackStarted: true });
				break;
			// no default
		}
	};

	const hasOnlyAdviceWithZorgMail = conversation.some(
		(item) => item.type === 'advice' && item.advice.startZorgMailRequestProcedure === true && !item.advice.startContactRequestProcedure
	);

	const successLabel = useMemo(() => {
		const successUrgency = `successUrgency${urgence.replace('U', '')}`;
		const baseLabel = (settings.contactRequest.labels as { [key: string]: string })[successUrgency];

		if (baseLabel) {
			if (callbackDateTime) {
				const daysDiff = callbackDateTime.diffNow('days');
				if (daysDiff.days < 1) {
					return baseLabel.replace(
						'{CallbackTime}',
						callbackDateTime.toJSDate().toLocaleString(settings.selectedLanguage.locale, { timeStyle: 'short' })
					);
				} else {
					return baseLabel.replace(
						'{CallbackTime}',
						callbackDateTime.toJSDate().toLocaleString(settings.selectedLanguage.locale, { dateStyle: 'short', timeStyle: 'short' })
					);
				}
			} else {
				return baseLabel.replace('{CallbackTime}', 'onbekend');
			}
		}
	}, [urgence, callbackDateTime, settings.contactRequest.labels, settings.selectedLanguage.locale]);

	return (
		<SAA.ActivityGroup>
			<SAA.GeneralAdvice>
				<SAA.AdviceHeader style={{ flexDirection: 'row-reverse' }}>
					<SAA.AdviceHeaderButton
						onClick={() => void toggleModal(true)}
					>
						{getLabel('AdviceButtonLabelClose', settings.applicationTexts, true)}
						<FontAwesomeIcon icon={faXmark} />
					</SAA.AdviceHeaderButton>
				</SAA.AdviceHeader>
				<SAA.GeneralAdviceAdvice>
					{!isMobile && (
						<SAA.GeneralAdviceAdviceContent>
							<SA.BubbleTitle ref={focusLastActivity} tabIndex={-1}>
								{/* Terugbelverzoek ingepland of ZorgMail bericht verzonden */}
								{hasOnlyAdviceWithZorgMail
									? getLabel('ZorgMailRequestSentTitle', settings.applicationTexts)
									: getLabel('callbackRequestSentTitle', settings.applicationTexts)}
							</SA.BubbleTitle>
						</SAA.GeneralAdviceAdviceContent>
					)}

					<SAA.ProfileImageWrapper>
						<SAA.ProfileImage $profileImage={ConfirmationImage} />
					</SAA.ProfileImageWrapper>
				</SAA.GeneralAdviceAdvice>
			</SAA.GeneralAdvice>

			<SAA.AdviceContent>
				<div style={{ display: 'flex', flex: 1 }} />

				<SAA.Advice>
					<SAA.ActivityBubble>
						{isMobile && (
							<SA.BubbleTitle ref={focusLastActivity} tabIndex={-1}>
								{/* Terugbelverzoek ingepland */}
								{getLabel('callbackRequestSentTitle', settings.applicationTexts)}
							</SA.BubbleTitle>
						)}

						{successLabel}
					</SAA.ActivityBubble>

					{(timeSlot || (useFirstAvailableTimeSlot && callbackTime)) && (
						<SAA.ActivityBubble>
							<div>
								<b>{getLabel('timeSlotCallbackRequestTitle', settings.applicationTexts, true)}</b>
								<p>{getLabel('callbackRequestConfirmationIntro', settings.applicationTexts, true)}</p>
							</div>

							<S.AppointmentBullets>
								<S.AppointmentBullet>
									<S.AppointmentBulletIcon icon={faAlignJustify} />
									{/* TODO label interpolation */}
									<span>
										{' '}
										{getLabel('ConfirmationAppointmentAboutPrefix', settings.applicationTexts, true)} {profile.bodypartName}
									</span>
								</S.AppointmentBullet>
								<S.AppointmentBullet>
									<S.AppointmentBulletIcon icon={faCalendarDay} />
									{timeSlot ? (
										<span> {fullDate(timeSlot.start, settings.selectedLanguage.code, true)}</span>
									) : (
										<span> {fullDate(callbackTime || '', settings.selectedLanguage.code, true)}</span>
									)}
								</S.AppointmentBullet>
								<S.AppointmentBullet>
									<S.AppointmentBulletIcon icon={faClock} />
									{timeSlot ? (
										<span> {timeInterval(timeSlot.start, timeSlot.end, settings.selectedLanguage.code)}</span>
									) : (
										<span> {timeRange(callbackTime || '', settings.selectedLanguage.code)}</span>
									)}
								</S.AppointmentBullet>
								{phoneNumber && (
									<S.AppointmentBullet>
										<S.AppointmentBulletIcon icon={faPhone} />
										<span> {phoneNumber}</span>
									</S.AppointmentBullet>
								)}
							</S.AppointmentBullets>
						</SAA.ActivityBubble>
					)}

					{/* Wat kan ik in de tussentijd doen */}
					{/* @ts-expect-error TODO, add field to API */}
					{advice?.inTheMeantime && (
						<SAA.ActivityBubble>
							<ExpandableDiv
								defaultExpanded
								title={getLabel('ConfirmationInTheMeantimeLabel', settings.applicationTexts, true)}
							>
								{/* @ts-expect-error TODO, add field to API */}
								{renderMarkdown(advice.inTheMeantime)}
							</ExpandableDiv>
						</SAA.ActivityBubble>
					)}

					{/* Goed om te weten */}
					{/* @ts-expect-error TODO, add field to API */}
					{settings.procedureCallbackGoodToKnow && (
						<SAA.ActivityBubble>
							<ExpandableDiv
								defaultExpanded
								title={getLabel('ConfirmationGoodToKnowLabel', settings.applicationTexts, true)}
							>
								{/* @ts-expect-error TODO, add field to API */}
								{renderMarkdown(settings.procedureCallbackGoodToKnow)}
							</ExpandableDiv>
						</SAA.ActivityBubble>
					)}

					{/* Bent u geholpen met dit advies? */}
					<SAA.ActivityBubble>
						<Feedback title={getLabel('ConfirmationFeedbackLabel', settings.applicationTexts, true)} />
					</SAA.ActivityBubble>

					{settings.survey.active && settings.survey.surveyId > 0 ? (
						<SAA.ActivityBubble>
							<SurveyIntro disabled={props.disabled} handleActivityResponse={handleSurveyAction} />
						</SAA.ActivityBubble>
					) : (
						showFollowupRestart && (
							<SAA.ActivityBubble>
								<EndButtons showFeedbackAction={true} handleActivityResponse={handleActivityEndActions} />
							</SAA.ActivityBubble>
						)
					)}
				</SAA.Advice>
			</SAA.AdviceContent>
		</SAA.ActivityGroup>
	);
};

export default ProcedureContactRequestEnd;
