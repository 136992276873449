import { CSSObjectWithLabel, GroupBase, OptionProps, StylesConfig } from 'react-select';
import type { ThemeType } from '../../models';

type OverridesType = {
	control?: CSSObjectWithLabel;
	singleValue?: CSSObjectWithLabel;
	input?: CSSObjectWithLabel;
	dropdownIndicator?: CSSObjectWithLabel;
	option?: CSSObjectWithLabel;
};

export default <Option = unknown, IsMulti extends boolean = boolean, Group extends GroupBase<Option> = GroupBase<Option>>(
	themeContext: ThemeType,
	overrides?: OverridesType
): StylesConfig<Option, IsMulti, Group> =>
	({
		control: (styles: CSSObjectWithLabel) => ({
			...styles,
			background: 'white',
			borderColor: themeContext.colors.accent,
			boxShadow: 'none',
			':hover': {
				...styles[':hover'],
				borderColor: themeContext.colors.accent
			},
			...overrides?.control
		}),
		singleValue: (styles: CSSObjectWithLabel) => ({
			...styles,
			color: themeContext.colors.text,
			...overrides?.singleValue
		}),
		input: (styles: CSSObjectWithLabel) => ({
			...styles,
			color: themeContext.colors.text,
			...overrides?.input
		}),
		dropdownIndicator: (styles: CSSObjectWithLabel) => ({
			...styles,
			color: themeContext.colors.text,
			':hover': {
				color: themeContext.colors.text
			},
			...overrides?.dropdownIndicator
		}),
		option: (styles: CSSObjectWithLabel, state: OptionProps<Option, IsMulti, Group>) => ({
			...styles,
			backgroundColor: state.isSelected ? themeContext.colors.accent : 'white',
			color: state.isSelected ? themeContext.colors.textinverted : themeContext.colors.text,
			':active': {
				backgroundColor: themeContext.colors.accent,
				color: themeContext.colors.textinverted
			},
			':hover': {
				...styles[':hover'],
				backgroundColor: state.isSelected ? themeContext.colors.accent : themeContext.colors.accent300
			},
			...overrides?.option
		})
	} as StylesConfig<Option, IsMulti, Group>);
