import React, { useCallback, useEffect, useRef, useMemo, useState } from 'react';

import S from './SectionContainer.styled';

import Dialog from '../Dialog/Dialog';
import Activity from '../Activity';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';
import PreviousNextButtons from '../PreviousNextButtons/PreviousNextButtons';
import useBrowserNavigation from '../../helpers/hooks/useBrowserNavigation';

const SectionContainer = () => {
	const [{ settings, conversation, modal, sectionIndex }, dispatch] = useStateContext();

	/* eslint-disable @typescript-eslint/no-empty-function */
	const [_handlePrevious, setHandlePrevious] = useState(() => () => {});
	const [_handleNext, setHandleNext] = useState(() => (pushHistoryState: boolean) => {});
	/* eslint-enable */
	const [disablePrevious, setDisablePrevious] = useState(false);
	const [disableNext, setDisableNext] = useState(false);
	const [isLoading, setIsLoading] = useState(false);

	const handleToggleModal = useToggleModal();
	const { on, incrementIndex, decrementIndex } = useBrowserNavigation(modal.open ?? false);

	const currentSection = useMemo(() => (sectionIndex < conversation.length ? conversation[sectionIndex] : conversation.at(-1)), [sectionIndex, conversation]);
	const isFirstActivity = useMemo(() => sectionIndex === 0 && conversation.length === 1, [sectionIndex, conversation.length]);
	const lastActivityIndex = useMemo(() => (conversation.length > 0 ? conversation.length - 1 : 0), [conversation.length]);
	const isNextSectionAvailable = useMemo(
		() => !!currentSection?.answer && lastActivityIndex >= sectionIndex,
		[currentSection?.answer, lastActivityIndex, sectionIndex]
	);

	const nextEnabledForSection = useMemo(() => ['requestCallback', 'callbackRequestScheduler'].includes(currentSection?.type ?? ''), [currentSection?.type]);
	const nextEnabled = useMemo(
		() => (nextEnabledForSection ? !disableNext : isNextSectionAvailable && !disableNext),
		[isNextSectionAvailable, disableNext, nextEnabledForSection]
	);
	const hidePreviousNext = useMemo(() => currentSection && ['advice', 'requestCallbackEnd'].includes(currentSection.type), [currentSection]);

	const currentIndexRef = useRef(sectionIndex);
	useEffect(() => {
		currentIndexRef.current = sectionIndex;
	}, [sectionIndex]);

	// Used to check when the modal should close.
	// Usually the modal opens with the second section (index 1)
	const closeModalIndexRef = useRef(1);
	useEffect(() => {
		if (isFirstActivity && modal.open) {
			// Modal opened with the first section (e.g., restart widget or widgetType button)
			closeModalIndexRef.current = 0;
		}
	}, [isFirstActivity, modal.open]);

	const handleNext = useCallback(
		(pushHistoryState = false) => {
			dispatch({ type: 'currentSectionIndex/increment' });
			if (pushHistoryState) incrementIndex();
		},
		[dispatch, incrementIndex]
	);

	const handlePrevious = useCallback(async () => {
		if (currentIndexRef.current === closeModalIndexRef.current) {
			if (await handleToggleModal(false)) return;

			// Note: This causes the user to be unable to go forward with browser navigation
			// If omitted, causes user to be unable to go back after canceling close modal dialog
			incrementIndex();
			return;
		}
		decrementIndex();
		dispatch({ type: 'currentSectionIndex/decrement' });
	}, [dispatch, incrementIndex, decrementIndex, handleToggleModal]);

	useEffect(() => {
		on('back', () => void handlePrevious());
		on('forward', () => handleNext(false));
	}, [handlePrevious, handleNext, on]);

	useEffect(() => {
		const handleKeydown = (ev: KeyboardEvent) => {
			if (ev.key.toLowerCase() === 'enter' && nextEnabled) {
				ev.preventDefault();
				_handleNext(true);
			}
		};

		document.addEventListener('keydown', handleKeydown);

		return () => {
			document.removeEventListener('keydown', handleKeydown);
		};
	}, [_handleNext, nextEnabled]);

	useEffect(() => {
		if (typeof settings.sessionTimeOutInSec === 'number' && settings.sessionTimeOutInSec > 0 && modal.open) {
			const timeoutId = setTimeout(() => {
				void handleToggleModal();
				dispatch({
					type: 'updateProfile/reset',
					forceInitialStateEmpty: true // make sure date of birth is empty on widget restart
				});
			}, settings.sessionTimeOutInSec * 1000);
			return () => {
				clearTimeout(timeoutId);
			};
		}
	}, [modal.open, conversation, settings.sessionTimeOutInSec, handleToggleModal, dispatch]);

	// Reset previous/next button handlers whenever activity changes
	useEffect(() => {
		setHandlePrevious(() => handlePrevious);
		setHandleNext(() => handleNext);
		setDisablePrevious(false);
		setIsLoading(false);
		setDisableNext(false);
	}, [sectionIndex, lastActivityIndex]);

	useEffect(() => {
		dispatch({
			type: 'setCurrentSection',
			currentSection
		});
	}, [currentSection, dispatch]);

	const internalHandleNext = useMemo(() => () => void handleNext(true), [handleNext]);
	const internalHandlePrevious = useMemo(() => () => void handlePrevious(), [handlePrevious]);

	if (!currentSection) {
		return <S.SectionContainer />;
	}

	return (
		<S.SectionContainer>
			<Dialog />
			<S.Section>
				<Activity
					currentSection={currentSection}
					isFirstActivity={isFirstActivity}
					handleNext={internalHandleNext}
					handlePrevious={internalHandlePrevious}
					setHandlePrevious={setHandlePrevious}
					setHandleNext={setHandleNext}
					setDisablePrevious={setDisablePrevious}
					setDisableNext={setDisableNext}
					setIsLoading={setIsLoading}
				/>
			</S.Section>

			{!hidePreviousNext && modal.open && (
				<PreviousNextButtons
					modalOpen={modal.open ?? false}
					disabledPrevious={disablePrevious}
					loading={isLoading}
					disabledNext={!nextEnabled}
					onPrevious={() => window.history.back()}
					onNext={() => _handleNext(true)}
				/>
			)}
		</S.SectionContainer>
	);
};

export default SectionContainer;
