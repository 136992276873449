import type { RootContext } from '../../models';
import { InitialProfile } from '../constants/initialState';
import { RootReducerAction } from './rootReducerActions';

export type RootReducerType = (state: RootContext, action: RootReducerAction) => RootContext;

export const rootReducer: RootReducerType = (state: RootContext, action: RootReducerAction): RootContext => {
	switch (action.type) {
		case 'updateSettings':
			return {
				...state,
				settings: action.settings
			};
		case 'updateLanguages':
			return {
				...state,
				languages: action.languages
			};
		case 'updateProfile':
			return {
				...state,
				profile: action.profile
			};
		case 'updateProfile/Age':
			return {
				...state,
				profile: {
					...state.profile,
					dob: action.dob,
					age: action.age
				}
			};
		case 'updateProfile/Gender':
			return {
				...state,
				profile: {
					...state.profile,
					gender: action.gender
				}
			};
		case 'updateProfile/reset': {
			const newProfile = InitialProfile(action.forceInitialStateEmpty);
			const currentProfile = state.profile;

			if (!action.forceInitialStateEmpty) {
				newProfile.dob = currentProfile.dob;
				newProfile.age = currentProfile.age;
				newProfile.gender = currentProfile.gender;
			}
			return {
				...state,
				profile: newProfile
			};
		}
		case 'session/update':
		case 'updateSession':
			return {
				...state,
				session: action.session ?? { id: '', token: '' }
			};

		case 'updateModal':
			return {
				...state,
				modal: action.modal
			};
		case 'conversation/setStepAnswer': {
			// react object update vs statechange. a new array/object has to be created to trigger statechange.
			const conversationSetStepAnswer = [...state.conversation];
			if (action.stepId) {
				conversationSetStepAnswer.forEach((item) => {
					if (item.id === action.stepId) {
						item.answer = action.answer as typeof item.answer;
					}
				});
			}
			return {
				...state,
				conversation: conversationSetStepAnswer
			};
		}
		case 'conversation/addStep': {
			// react object update vs statechange. a new array/object has to be created to trigger statechange.
			const conversationWithStep = [
				...state.conversation,
				{
					...action.step,
					step: state.conversation.length + 1
				}
			];
			return {
				...state,
				conversation: conversationWithStep
			};
		}
		case 'conversation/setNextStep': {
			const currentStepIndex = state.conversation.findIndex((step) => step.id === action.currentStepId);
			// Ensure that nextStep is the final step in the conversation
			const newConversation = state.conversation.slice(0, currentStepIndex + 1);
			const updatedConversation = [
				...newConversation,
				{
					...action.step,
					step: newConversation.length + 1
				}
			];
			return {
				...state,
				conversation: updatedConversation
			};
		}
		case 'conversation/updateStep': {
			const newConversation = state.conversation.map((c) => {
				if (c.id === action.step.id) {
					return action.step;
				}

				return c;
			});

			return {
				...state,
				conversation: newConversation
			};
		}
		case 'updateConversationStep': {
			// react object update vs statechange. a new array/object has to be created to trigger statechange.
			const conversationUpdateStep = [...state.conversation];
			if (action.stepIndex >= 0 && action.stepIndex < conversationUpdateStep.length) {
				conversationUpdateStep[action.stepIndex] = action.step;
			}
			return {
				...state,
				conversation: conversationUpdateStep
			};
		}
		case 'updateConversationActivities': {
			// react object update vs statechange. a new array/object has to be created to trigger statechange.
			const newConversation = (conversation = []) => [...action.conversation];
			return {
				...state,
				conversation: newConversation()
			};
		}
		case 'currentSectionIndex/reset': {
			return {
				...state,
				sectionIndex: 0
			};
		}
		case 'currentSectionIndex/increment': {
			return {
				...state,
				sectionIndex: state.sectionIndex + 1
			};
		}
		case 'currentSectionIndex/decrement': {
			return {
				...state,
				sectionIndex: state.sectionIndex - 1
			};
		}
		case 'updateRefs':
			return {
				...state,
				refs: action.refs
			};
		case 'dialog/setConfirm':
			return {
				...state,
				dialog: {
					...state.dialog,
					confirm: action.confirm
				}
			};
		case 'dialog/setState':
			return {
				...state,
				dialog: {
					confirmPromiseResolve: state.dialog.confirmPromiseResolve,
					...action.state,
					confirm: state.dialog.confirm
				}
			};
		case 'setCurrentSection':
			return {
				...state,
				currentSection: action.currentSection
			};
		default:
			return state;
	}
};
