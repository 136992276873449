import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding-right: ${(props) => props.theme.spacings.smallMedium};

	@media ${(props) => props.theme.screens.medium} {
		padding-right: 0;
	}
`;

const Label = styled.div`
	font-size: ${(props) => props.theme.fontSizes.base};
	line-height: 40px;
`;

const Buttons = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${(props) => props.theme.spacings.small};
	align-items: center;
`;

const Button = styled(FontAwesomeIcon)`
	&.positive {
		background: #e5f7ed; // TODO introduce success-100 color in theme
		color: ${(props) => props.theme.colors.success};

		&.active {
			border: solid 2px ${(props) => props.theme.colors.success};
			padding: calc(${(props) => props.theme.spacings.smallMedium} - 2px);
		}
	}
	&.negative {
		background: #fae9e5; // TODO introduce error-100 color in theme
		color: ${(props) => props.theme.colors.error};

		&.active {
			border: solid 2px ${(props) => props.theme.colors.error};
			padding: calc(${(props) => props.theme.spacings.smallMedium} - 2px);
		}
	}

	padding: ${(props) => props.theme.spacings.smallMedium};
	width: 40px;
	height: 40px;
	border-radius: ${(props) => props.theme.radii.default};
`;

const components = {
	Wrapper,
	Label,
	Buttons,
	Button
};

export default components;
