// https://github.com/diegohaz/arc/wiki/Styling

import type {
	Settings,
	ThemeColors,
	ThemeFonts,
	ThemeScreens,
	ThemeSizes,
	ThemeType,
	ThemeBreakpoints,
	ThemeSpacings,
	ThemeRadii,
	ThemeFontSizes
} from '../../models';

/**
 * Converts a hex color string to an HSL color tuple.
 * @param hex A hex color string.
 * @returns A tuple [hue, saturation, lightness].
 */
function toHSL(hex: string): Array<number> {
	hex = hex.replace(/^#/, '');
	let r = parseInt(hex.substring(0, 2), 16);
	let g = parseInt(hex.substring(2, 4), 16);
	let b = parseInt(hex.substring(4, 6), 16);

	r /= 255;
	g /= 255;
	b /= 255;

	const cmin = Math.min(r, g, b),
		cmax = Math.max(r, g, b),
		delta = cmax - cmin;

	let h = 0;
	let s = 0;
	let l = 0;

	if (delta === 0) h = 0;
	else if (cmax === r) h = ((g - b) / delta) % 6;
	else if (cmax === g) h = (b - r) / delta + 2;
	else h = (r - g) / delta + 4;

	h = Math.round(h * 60);
	if (h < 0) h += 360;

	l = (cmax + cmin) / 2;
	s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));

	s = +(s * 100).toFixed(1);
	l = +(l * 100).toFixed(1);

	return [h, s, l];
}

/**
 * Converts a hex color string to an HSL color string with modified saturation and lightness.
 * @param hexAccent The original hex color string.
 * @param sPerc The percentage to modify the saturation of the original color.
 * @param lPerc The new lightness percentage for the color.
 * @returns The HSL color string.
 */
function getColor(hexAccent: string, sPerc: number, lPerc: number): string {
	const [h, originalSaturation] = toHSL(hexAccent);

	// Calculate new saturation based on the input percentage
	const s = (originalSaturation * sPerc) / 100;

	return `hsl(${h}, ${s}%, ${lPerc}%)`;
}

const darkenColor = (hexColor: string, darkenAmount = 0.2): string => {
	const hexToRgb = (hex: string) => {
		const bigint = parseInt(hex.slice(1), 16);
		let r = (bigint >> 16) & 255;
		let g = (bigint >> 8) & 255;
		let b = bigint & 255;

		// Decrease each component by the specified percentage, ensuring it doesn't go below 0
		r = Math.max(0, r - Math.floor(255 * darkenAmount));
		g = Math.max(0, g - Math.floor(255 * darkenAmount));
		b = Math.max(0, b - Math.floor(255 * darkenAmount));

		return `${r}, ${g}, ${b}`;
	};
	return `rgb(${hexToRgb(hexColor)})`;
};

const calculateRgba = (accentColor: string, alpha: number): string => {
	const hexToRgb = (hex: string) => {
		const bigint = parseInt(hex.slice(1), 16);
		const r = (bigint >> 16) & 255;
		const g = (bigint >> 8) & 255;
		const b = bigint & 255;
		return `${r}, ${g}, ${b}`;
	};
	return `rgba(${hexToRgb(accentColor)}, ${alpha})`;
};

export const Theme = (props: Settings): ThemeType => {
	let fontFamily = 'Inter, Montserrat';
	if (props.branding && props.branding.font_family) {
		switch (props.branding.font_family) {
			case 'Arial':
				fontFamily = 'Arial, "Helvetica Neue", Helvetica, "Liberation Sans", Arimo';
				break;
			case 'Verdana':
				fontFamily = 'Verdana, "Helvetica Neue", Helvetica, "Liberation Sans", Arimo';
				break;
			case 'Calibri':
				fontFamily = 'Calibri, Carlito';
				break;
			case 'Roboto':
				fontFamily = 'Roboto, Arial, "Helvetica Neue", Helvetica, "Liberation Sans", Arimo';
				break;
			default:
				fontFamily = 'Inter, Montserrat';
				break;
		}
	}

	fontFamily += ', sans-serif';

	// some test colors, leave here for now
	//const accentColor = '#000AB4' ?? '#000'; // blauw, Groningen
	//const accentColor = '#EB4B8C' ?? '#000'; // Doktersdient Groningen
	//const accentColor = '#006066' ?? '#000'; // figma design 500
	const accentColor = props.accentColor ?? '#000';

	// TODO: cleanup unused colors later
	const themeColors: ThemeColors = {
		accent: accentColor,
		accentLight: calculateRgba(accentColor, 0.05),
		accentLighter: calculateRgba(accentColor, 0.4),
		accentDarker: darkenColor(accentColor), // note: is calculated differently than accentLighter
		text: '#60606a',
		textdarker: '#4d4d4d',
		textinverted: '#fff',
		focus: accentColor,

		// interface colors, based on accent color. See figma design.
		accent100: getColor(accentColor, 10, 95),
		accent200: getColor(accentColor, 15, 90),
		accent300: getColor(accentColor, 20, 80),
		accent400: getColor(accentColor, 25, 50),
		accent600: getColor(accentColor, 80, 18),
		accent700: getColor(accentColor, 60, 16),
		accent800: getColor(accentColor, 40, 14),
		accent900: getColor(accentColor, 20, 12),

		borderLight: '#0000001a',
		disabled: '#FCFCFC',

		// text colors, see figma design
		text100: '#FCFCFC',
		text200: '#F7F8F8',
		text300: '#EAEBEB',
		text400: '#D5D7D7',
		text500: '#B7BDBD',
		text600: '#858E8E',
		text700: '#6D7878',
		text800: '#2F3737',
		text900: '#1C2122',

		// functional colors, see figma design
		error: '#CC2200',
		errorDarker: '#A31B00',
		success: '#00B24A',
		succesDarker: '#008A39',
		noticeDefault: '#FFF9E5',
		noticeSuccess: '#E5F7ED',
		noticeDanger: '#FAE9E5'
	};

	// Partially inspired by https://tailwindcss.com/docs/font-size
	// At least for now, use pixels. Possibly use rems again later, or use something like
	// :root {
	//	--base-size: 16px;
	//}
	// on an element hight in the document tree, or in GlobalStyle.ts
	// note:
	//  html {
	//	 font-size: 100%;
	//}
	// in GlobalStyle.ts isn't acceptable because that changes the font-size of the website around
	// the widget as well
	//
	// Or possibly use em for sizes, and set 16px as default font size on widget root
	//
	// Note: https://hpdedolgaard.praktijkinfo.nl/ has
	// html {
	//     font-size: 10px;
	// },
	// Thus in this case 1rem = 10px, so usage of rem in the widget would lead to too small letters.
	//
	// TODO: make font size responsive to the website's font size, for accessibility reasons
	const fontSizes: ThemeFontSizes = {
		xs: '12px', // 0.75rem
		sm: '14px', // 0.875rem
		base: '16px', // 1rem
		lg: '18px', // 1.125rem
		xl: '20px', // 1.25rem
		xl2: '24px', // 1.5rem
		xl3: '28px', // 1.75rem
		xl4: '32px', // 2rem
		xl5: '36px' // 2.25rem
	};

	const spacings: ThemeSpacings = {
		extraSmall: '4px', // 0.25rem
		small: '8px', // 0.5rem
		smallMedium: '12px', // 0.75rem
		medium: '16px', // 1rem
		large: '24px', // 1.5rem
		extraLarge: '32px', // 2rem
		extraExtraLarge: '40px', // 2.5rem, maybe use extraLarge instead
		extraExtraExtraLarge: '48px', // 3rem
	};
	// const spacings: ThemeSpacings = {
	// 	extraSmall: '0.25rem', // 4px
	// 	small: '0.5rem', // 8px
	// 	smallMedium: '0.75rem', // 12px
	// 	medium: '1rem', // 16px
	// 	large: '1.5rem', // 24px
	// 	extraLarge: '2rem' // 32px
	// };

	const radii: ThemeRadii = {
		default: '5px' // TODO: apply
	};

	const themeFonts: ThemeFonts = {
		primary: fontFamily, //Helvetica Neue, Helvetica, Roboto, sans-serif',
		pre: 'Consolas, Liberation Mono, Menlo, Courier, monospace',
		quote: 'Georgia, serif'
	};

	const themeSizes: ThemeSizes = {
		maxWidth: 1140,
		maxHeight: 860
	};

	const largeBreakpoint = 992;
	const mediumBreakpoint = 768; // inspired by https://getbootstrap.com/docs/5.2/layout/breakpoints/
	const narrowBreakpoint = 350; // added because of widget display in narrow container (desktop) at https://huisartsenpraktijkdebrink.uwartsonline.nl/

	const themeBreakpoints: ThemeBreakpoints = {
		narrow: narrowBreakpoint,
		medium: mediumBreakpoint
	};

	const themeScreens: ThemeScreens = {
		extraSmall: '(max-width: 480px)',
		small: `(max-width: ${mediumBreakpoint}px)`, // deprecated, use medium breakpoint instead (desktop is the exception). TODO: remove usages from code
		medium: `(min-width: ${mediumBreakpoint}px)`,
		large: `(min-width: ${largeBreakpoint}px)`
	};

	return {
		colors: themeColors,
		fontSizes: fontSizes,
		spacings: spacings,
		radii: radii,
		fonts: themeFonts,
		sizes: themeSizes,
		screens: themeScreens,
		breakpoints: themeBreakpoints,
		isRtl: props.selectedLanguage.isRtl
	};
};
