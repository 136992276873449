import React, { useState, useEffect, useCallback, FunctionComponent, useMemo } from 'react';
import debounce from 'lodash.debounce';

import { useTheme } from 'styled-components';
import ReactTooltip from 'react-tooltip';

import S from './SectionComplaint.styled';
import SP from '../Procedure/Procedure.styled';

import ActivityBubbleTitle from '../ActivityBubbleTitle';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import { getLabel } from '../../helpers/constants/getLabels';
import type { ActivityAnswerComplaintSelector, ActivityStepComplaintSelector, Triage } from '../../models';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { DEFAULT_SEARCH_DEBOUNCE_DELAY } from '../../models/widget/WidgetConfig';
import { ActivityProps } from '../Activity';
import { useWindowSize } from '../../helpers/hooks/useWindowSize';
import useCurrentStepNumber from '../../helpers/hooks/useCurrentStepNumber';

const splitOnMultiple = (str: string, splitOn: string[]) => {
	const result = [] as string[];
	let curr = '';
	for (let i = 0; i < str.length; i++) {
		for (let j = 0; j < splitOn.length; j++) {
			if (str.indexOf(splitOn[j]) === i) {
				result.push(curr);
				result.push(splitOn[j]);
				i += splitOn[j].length;
				curr = '';
			}
		}
		if (i < str.length) {
			curr += str[i];
		}
	}
	result.push(curr);
	return result;
};

interface SectionComplaintProps extends ActivityProps {
	currentStep: ActivityStepComplaintSelector;
}

// Use this value as default for complaints to prevent warnings in useMemo (new array would always give new result)
const emptyTriagesArray: readonly Triage[] = [];

const SectionComplaint: FunctionComponent<SectionComplaintProps> = (props) => {
	const [{ settings }, dispatch] = useStateContext();
	const themeContext = useTheme();

	const complaints = props.currentStep.triages ?? emptyTriagesArray;

	const [autoFocusCompaint, setAutoFocusComplaint] = useState(false);
	const [complaint, setComplaint] = useState<number | undefined>(props.currentStep.answer?.triageId);
	const [query, setQuery] = useState<string>('');
	const [filteredComplaints, setFilteredComplaints] = useState<readonly Triage[]>(complaints);
	const selectedComplaint = useMemo(() => complaints.find((c) => c.id === complaint), [complaint, complaints]);

	const stepNumber = useCurrentStepNumber(props.currentStep.id);
	const isMobile = useWindowSize().width < themeContext.breakpoints.medium;

	const bubbleText = getLabel('QuestionWhatTriageWithKnownBodyPart', settings.applicationTexts, true).replace(
		'%s',
		props.currentStep.bodypartName?.toLowerCase() ?? ''
	);

	const debouncedSearch = useMemo(() => {
		const handleSearch = (query: string) => {
			const trimmed = query.trim().toLowerCase();
			if (!trimmed) {
				setFilteredComplaints(complaints);
				return;
			}

			const searchParts = trimmed.split(' ');
			const complaintsWithMatchingTitle = complaints.filter((c) => searchParts.every((part) => c.title.indexOf(part) >= 0));
			const complaintsWithMatchingSynonym = complaints.filter((c) =>
				searchParts.every((part) => c.synonyms !== undefined && c.synonyms.find((s) => s.indexOf(part) >= 0))
			);

			// Prioritizes triages where the first word in the search query is in the title
			// Sort by how soon it appears in the title
			// Sort all other triages (where the synonym matches the search term) alphabetically
			const compare = (a: Triage, b: Triage, search: string) => {
				if (a.title.includes(search) && !b.title.includes(search)) {
					return -1;
				} else if (!a.title.includes(search) && b.title.includes(search)) {
					return 1;
				} else {
					return a.title.indexOf(searchParts[0]) - b.title.indexOf(searchParts[0]);
				}
			};
			const finalComplaintsList = [...complaintsWithMatchingTitle, ...complaintsWithMatchingSynonym]
				.filter((triage, index, self) => self.indexOf(triage) === index)
				.sort((a, b) => compare(a, b, searchParts[0]));
			setFilteredComplaints(finalComplaintsList);
			return;
		};

		// @ts-expect-error setting does not exist yet
		return debounce(handleSearch, settings.complaintSearchDebounceTimer ?? DEFAULT_SEARCH_DEBOUNCE_DELAY);
		// @ts-expect-error setting does not exist yet
	}, [complaints, settings.complaintSearchDebounceTimer]);

	useEffect(() => {
		if (query !== undefined) {
			debouncedSearch(query);
		}
	}, [query, complaints]);

	const firstComplaintFocusRef = useCallback(
		(node: HTMLButtonElement | null) => {
			if (node !== null) {
				if (autoFocusCompaint) {
					node.focus();
					setAutoFocusComplaint(false);
				}

				// Scroll to the complaints when we are on a small screen
				if (complaints && complaints.length > 0) {
					const screenSmallMediaQueryList = window.matchMedia(themeContext.screens.small);
					if (screenSmallMediaQueryList.matches) {
						(node.parentNode?.parentNode?.parentNode as HTMLElement)?.scrollIntoView({ block: 'nearest', inline: 'nearest' });
					}
				}
			}
		},
		[autoFocusCompaint, complaints, themeContext.screens.small]
	);

	useEffect(() => {
		if (selectedComplaint) {
			props.setHandleNext(() => async () => {
				props.setDisableNext(true);
				const next = await handleActivityResponse(selectedComplaint);
				props.setDisableNext(false);

				if (next !== false) {
					props.handleNext();
				} else {
					setComplaint(props.currentStep.answer?.triageId);
				}
			});
		}
	}, [selectedComplaint]);

	const handleSelectComplaint = (triage: Triage) => {
		setComplaint(triage.id);
		if (!props.currentStep.answer) {
			dispatch({
				type: 'conversation/setStepAnswer',
				stepId: props.id,
				answer: {
					bodyAreaId: props.currentStep.bodypart as number,
					triageId: triage.id,
					complaintLabel: triage.title
				}
			});
		}
	};

	const handleActivityResponse = async (triage: Triage) => {
		return await props.handleActivityResponse({
			bodyAreaId: props.currentStep.bodypart as number,
			triageId: triage.id,
			complaintLabel: triage.title
		} as ActivityAnswerComplaintSelector);
	};

	const renderTriageLabel = (title: string) => {
		if (!query?.trim() || filteredComplaints.length === complaints.length) {
			return (
				<span>
					{title}
				</span>
			);
		}
		return (
			<>
				{splitOnMultiple(title, query.trim().split(' ')).map((str, i) => (
					<>{!!str && <span key={str}>{i % 2 === 0 ? str : <S.TriageHighlight>{str}</S.TriageHighlight>}</span>}</>
				))}
			</>
		);
	};

	return (
		<S.ActivityBubble>
			<ActivityBubbleTitle
				title={bubbleText}
				isFirstActivity={props.isFirstActivity}
				isLastActivity={props.isLastActivity}
				modalOpen={props.modalOpen}
				disabled={props.disabled}
				stepNumber={stepNumber}
			/>
			<S.ActivityComplaintWrapper>
				{complaints?.length > 0 && (
					<S.Complaints>
						<S.SearchField>
							<SP.Field
								type="text"
								placeholder={`${getLabel('WidgetSearchTriagePlaceholder', settings.applicationTexts, true)}`}
								autoComplete="off"
								value={query}
								onChange={(e) => setQuery(e.target.value)}
								tabIndex={0}
								autoFocus={!isMobile}
								disabled={props.disabled}
							/>
							<S.SearchIcon icon={faMagnifyingGlass} />
						</S.SearchField>

						<S.ComplaintsList style={{ ...!filteredComplaints.length ? { columnCount: 1 } : {} }}>
							{filteredComplaints.map((item, index) => (
								<S.Complaint key={item.id}>
									<S.NewComplaintButton
										disabled={props.disabled}
										onClick={() => !props.disabled && handleSelectComplaint(item)}
										$selected={item.id === complaint}
										ref={index === 0 ? firstComplaintFocusRef : null}
										tabIndex={index + 1}
									>
										{renderTriageLabel(item.title)}
									</S.NewComplaintButton>
								</S.Complaint>
							))}
							{!filteredComplaints.length && (
								<div>
									{getLabel('WidgetSearchTriageNoResults', settings.applicationTexts)}
								</div>
							)}
						</S.ComplaintsList>
					</S.Complaints>
				)}

				{!props.disabled && (
					<ReactTooltip globalEventOff="click" disableInternalStyle={true}>
						{}
					</ReactTooltip>
				)}
			</S.ActivityComplaintWrapper>
		</S.ActivityBubble>
	);
};

export default SectionComplaint;
