import { useCallback } from 'react';
import { getLabel } from '../constants/getLabels';

import { useResetWidget } from './useResetWidget';
import { useStateContext } from './useStateContext';

export const useToggleModal = () => {
	const [
		{
			modal,
			conversation,
			session,
			settings,
			dialog: { confirm }
		},
		dispatch
	] = useStateContext();
	const resetWidget = useResetWidget();

	return useCallback(
		async (immediate = true) => {
			const previousOpenValue = modal.open;
			const newOpenValue = !modal.open;

			let finished = false;
			if (conversation && conversation.length > 0) {
				finished = conversation.some((a) => a.type === 'advice' && a.visited);
			}

			if (
				!newOpenValue &&
				!finished &&
				!immediate &&
				!(await confirm({
					header: getLabel('CloseWidgetUnfinishedWarningHeader', settings.applicationTexts, true),
					message: getLabel('CloseWidgetUnfinishedWarningMessage', settings.applicationTexts, true),
					cancelLabel: getLabel('CloseWidgetCancel', settings.applicationTexts, true),
					confirmLabel: getLabel('QuestionDefaultYesLabel', settings.applicationTexts, true)
				}))
			) {
				return false;
			}

			dispatch({
				type: 'updateModal',
				modal: {
					...modal,
					open: newOpenValue
				}
			});

			// We reset the profile when closing the model, so previous body area etc. is not remembered
			if (previousOpenValue) {
				// Is open now, so is closing
				await resetWidget(false);
			}

			if (newOpenValue) {
				if (settings.target instanceof Element) {
					settings.target.dispatchEvent(new CustomEvent('minddWidgetOpened', { bubbles: true }));
				}
				if (typeof settings.onWidgetOpened === 'function') {
					settings.onWidgetOpened.call(null);
				}
			} else {
				if (settings.target instanceof Element) {
					settings.target.dispatchEvent(
						new CustomEvent('minddWidgetClosed', { bubbles: true, detail: { finished: finished, sessionId: session.id } })
					);
				}
				if (typeof settings.onWidgetClosed === 'function') {
					settings.onWidgetClosed.call(null, finished, session.id);
				}
			}

			return true;
		},
		[
			modal,
			session.id,
			conversation,
			dispatch,
			confirm,
			resetWidget,
			settings.onWidgetOpened,
			settings.onWidgetClosed,
			settings.applicationTexts,
			settings.target
		]
	);
};
