import React, { ChangeEvent, forwardRef, FunctionComponent, useEffect, useMemo, useState } from 'react';
import Select from 'react-select';
import { useTheme } from 'styled-components';

import S from './styled';

import { getLabel } from '../../helpers/constants/getLabels';
import { useStateContext } from '../../helpers/hooks/useStateContext';
import type { ActivityAnswerAgeSelector, CalculatedAge, DateOfBirth } from '../../models';
import useSelectStyles from '../../helpers/styled/Select';
import { useWindowSize } from '../../helpers/hooks/useWindowSize';
import ErrorMessage from '../ErrorMessage/ErrorMessage';

type ValidationStatus = '' | 'valid' | 'invalid' | 'maxAgeExceeded';

type AgeYearInputProps = {
	confirmButtonClassName: string;
	disabled: boolean;
	step?: number;
	title: string | JSX.Element;
	answer?: { dob?: DateOfBirth; age: CalculatedAge; yearInput?: string; ageUnit?: AgeUnit; additionalInput?: string };
	onChange: (answer?: ActivityAnswerAgeSelector) => void;
};

export enum AgeUnit {
	Year = 'jaar',
	Month = 'maanden',
	Week = 'weken'
}

type AgeYearLabels = {
	WidgetAgeYearLabel: string;
	WidgetDisplayAgeYearsUnit: string;
	WidgetDisplayAgeMonthsUnit: string;
	WidgetDisplayAgeMonthUnit: string;
	WidgetDisplayAgeWeeksUnit: string;
	WidgetDisplayAgeWeekUnit: string;
	WidgetDateOfBirthRequestError: string;
	WidgetMaxAgeRequestError: string;
	WidgetConfirmButton: string;
	WidgetAgeWeekPlaceholder: string;
	WidgetAgeMonthPlaceholder: string;
	WidgetAgeYearShortPlaceholder: string;
	WidgetAgeDescription: string;
};

const AgeYearInput: FunctionComponent<AgeYearInputProps> = (props: AgeYearInputProps) => {
	const [{ profile, settings }, dispatch] = useStateContext();

	const labels = useMemo<AgeYearLabels>(
		() => ({
			WidgetAgeYearLabel: getLabel('WidgetAgeLabel', settings.applicationTexts, true),
			WidgetDisplayAgeYearsUnit: getLabel('WidgetDisplayAgeYearsUnit', settings.applicationTexts, true),
			WidgetDisplayAgeMonthsUnit: getLabel('WidgetDisplayAgeMonthsUnit', settings.applicationTexts, true),
			WidgetDisplayAgeMonthUnit: getLabel('WidgetDisplayAgeMonthUnit', settings.applicationTexts, true),
			WidgetDisplayAgeWeeksUnit: getLabel('WidgetDisplayAgeWeeksUnit', settings.applicationTexts, true),
			WidgetDisplayAgeWeekUnit: getLabel('WidgetDisplayAgeWeekUnit', settings.applicationTexts, true),
			WidgetDateOfBirthRequestError: getLabel('WidgetDateOfBirthRequestError', settings.applicationTexts, true),
			WidgetMaxAgeRequestError: getLabel('WidgetMaxAgeRequestError', settings.applicationTexts, true),
			WidgetConfirmButton: getLabel('WidgetConfirmButton', settings.applicationTexts, true),
			WidgetAgeWeekPlaceholder: getLabel('WidgetAgeWeekPlaceholder', settings.applicationTexts, true),
			WidgetAgeMonthPlaceholder: getLabel('WidgetAgeMonthPlaceholder', settings.applicationTexts, true),
			WidgetAgeYearShortPlaceholder: getLabel('WidgetAgeYearShortPlaceholder', settings.applicationTexts, true),
			WidgetAgeDescription: getLabel('WidgetAgeDescription', settings.applicationTexts, true)
		}),
		[settings.applicationTexts]
	);

	// if !forceInitialStateEmpty profile.age could be set from url parameter (initialState.ts)
	const [initialAge] = useState<string | null>(profile.age ?? null);
	const [age, setAge] = useState<CalculatedAge>(props.answer?.age ?? { age: '', ageInYears: 0, displayAge: '' });
	const [yearInput, setYearInput] = useState<string>(props.answer?.yearInput ?? '');
	const [ageUnit, setAgeUnit] = useState<AgeUnit>(props.answer?.ageUnit ?? AgeUnit.Year);
	const [additionalInput, setAdditionalInput] = useState<string>(props.answer?.additionalInput ?? '');
	const [validationStatus, setValidationStatus] = useState<ValidationStatus>(props.answer ? 'valid' : '');
	const themeContext = useTheme();
	const selectStyles = useSelectStyles<{ value: AgeUnit; label: string }>(themeContext);
	const isMobile = useWindowSize().width < themeContext.breakpoints.medium;

	const showAdditionalInput: boolean = useMemo(() => ageUnit === AgeUnit.Month || ageUnit === AgeUnit.Week, [ageUnit]);

	const updateAgeUnit = () => {
		const inputValue = ageUnit === AgeUnit.Year ? yearInput : additionalInput;
		const recalculatedAge = getCalculatedAge(inputValue, ageUnit, labels);

		if (recalculatedAge) {
			setAge(recalculatedAge);

			dispatch({
				type: 'updateProfile/Age',
				dob: { day: undefined, month: undefined, year: undefined },
				age: recalculatedAge.age
			});
		} else {
			setAge({ age: '', ageInYears: 0, displayAge: '' });

			dispatch({
				type: 'updateProfile/Age',
				dob: { day: undefined, month: undefined, year: undefined },
				age: undefined
			});
		}
	};

	useEffect(() => {
		updateAgeUnit();
	}, [ageUnit]);

	useEffect(() => {
		// profile.age isn't used here directly because that would trigger a rerun (via handleOnChange)
		// of this effect if profile.age is added to the dependency list. And omitting profile.age from the dependency list
		// would result in a compiler warning. Therefore introduced variable 'initialAge'.
		if (initialAge && !props.answer) {
			if (initialAge === '0') {
				setAgeUnit(AgeUnit.Month);
				setYearInput('0');
				setAge({ age: '0', ageInYears: 0, displayAge: '' });
			} else {
				setAgeUnit(AgeUnit.Year);
				const calculatedAge = getCalculatedAge(initialAge, AgeUnit.Year, labels);
				if (calculatedAge) {
					setYearInput(calculatedAge.ageInYears.toString());
					setAge(calculatedAge);
					setValidationStatus('valid'); // note: in other places is checked for valid values
					dispatch({
						type: 'updateProfile/Age',
						dob: { day: undefined, month: undefined, year: undefined },
						age: calculatedAge.age
					});
				}
			}
		}
	}, [dispatch, initialAge, labels, props.answer]);

	const handleOnChange = (field: ChangeEvent<HTMLInputElement>) => {
		const value = field.target.value.slice(0, field.target.maxLength).replace(/\D/, '');

		// Reset validationStatus
		setValidationStatus('');

		if (value === '0') {
			setAgeUnit(AgeUnit.Month);
			setYearInput(value);
			setAge({ age: '0', ageInYears: 0, displayAge: '' });
		} else {
			setAgeUnit(AgeUnit.Year);
			const calculatedAge = getCalculatedAge(value, AgeUnit.Year, labels);
			if (calculatedAge) {
				setYearInput(value);
				setAge(calculatedAge);
				setAdditionalInput('');

				dispatch({
					type: 'updateProfile/Age',
					dob: { day: undefined, month: undefined, year: undefined },
					age: calculatedAge.age
				});

				if (calculatedAge.ageInYears > settings.maxAge) {
					setValidationStatus('maxAgeExceeded');
				} else {
					setValidationStatus('valid');
				}
			} else {
				setYearInput('');
				setAge({ age: '', ageInYears: 0, displayAge: '' });

				dispatch({
					type: 'updateProfile/Age',
					dob: { day: undefined, month: undefined, year: undefined },
					age: undefined
				});
			}
		}
	};

	const handleOnChangeAdditional = (field: ChangeEvent<HTMLInputElement>) => {
		const value = field.target.value.slice(0, field.target.maxLength).replace(/\D/, '');

		const intValue = parseInt(value, 10);
		if ((ageUnit === AgeUnit.Month && intValue > 24) || (ageUnit === AgeUnit.Week && intValue > 52)) {
			setValidationStatus('maxAgeExceeded');
		} else {
			const calculatedAge = getCalculatedAge(value, ageUnit, labels);
			if (calculatedAge) {
				setAdditionalInput(value);
				setAge(calculatedAge);

				dispatch({
					type: 'updateProfile/Age',
					dob: { day: undefined, month: undefined, year: undefined },
					age: calculatedAge.age
				});

				if (calculatedAge?.age && calculatedAge.ageInYears > 0) {
					if (calculatedAge.ageInYears > settings.maxAge) {
						setValidationStatus('maxAgeExceeded');
					} else {
						setValidationStatus('valid');
					}
				} else {
					setValidationStatus('invalid');
				}
			} else {
				setAdditionalInput('');
				setAge({ age: '', ageInYears: 0, displayAge: '' });

				dispatch({
					type: 'updateProfile/Age',
					dob: { day: undefined, month: undefined, year: undefined },
					age: undefined
				});

				setValidationStatus('');
			}
		}
	};

	useEffect(() => {
		void props.onChange(
			validationStatus === 'valid'
				? {
						dob: { day: undefined, month: undefined, year: undefined },
						age,
						yearInput,
						ageUnit,
						additionalInput
				  }
				: undefined
		);
	}, [validationStatus, age]);

	const ageUnitOptions = [
		{ value: AgeUnit.Month, label: labels.WidgetDisplayAgeMonthsUnit },
		{ value: AgeUnit.Week, label: labels.WidgetDisplayAgeWeeksUnit }
	];

	return (
		<>
			<S.Row>
				<S.Col>
					<S.Label htmlFor={`MINDD-Widget-${props.step ?? 0}-AgeYearInput`}>{labels.WidgetAgeYearLabel}</S.Label>
					<S.Input
						id={`MINDD-Widget-${props.step ?? 0}-AgeYearInput`}
						disabled={props.disabled}
						autoComplete="off"
						name="year"
						maxLength={3}
						type="number"
						inputMode="numeric"
						pattern="[0-9]*"
						placeholder={labels.WidgetAgeYearShortPlaceholder}
						value={yearInput}
						onChange={handleOnChange}
						data-testid="age-year-input"
					/>
				</S.Col>
			</S.Row>
			{showAdditionalInput && (
				<>
					<S.Row>{labels.WidgetAgeDescription}</S.Row>
					<S.Row>
						<S.Col $flex={!isMobile ? 3 : undefined}>
							<S.Input
								id={`MINDD-Widget-${props.step ?? 0}-AgeAdditionalInput`}
								disabled={props.disabled}
								autoComplete="off"
								name="additionalInput"
								maxLength={3}
								type="number"
								inputMode="numeric"
								pattern="[0-9]*"
								placeholder={ageUnit === AgeUnit.Month ? labels.WidgetAgeMonthPlaceholder : labels.WidgetAgeWeekPlaceholder}
								value={additionalInput}
								onChange={handleOnChangeAdditional}
								data-testid="age-additional-input"
							/>
						</S.Col>
						<S.Col>
							<Select
								value={ageUnitOptions.find((o) => o.value === ageUnit)}
								options={ageUnitOptions}
								// @ts-expect-error e is never null, since the dropdown is not clearable
								// eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- e is never null, since the dropdown is not clearable
								onChange={(e) => setAgeUnit(e.value)}
								isClearable={false}
								styles={selectStyles}
								id="age-additional-select"
							/>
						</S.Col>
					</S.Row>
				</>
			)}

			<ErrorMessage showError={['invalid', 'maxAgeExceeded'].includes(validationStatus)}>
				{validationStatus === 'invalid' && labels.WidgetDateOfBirthRequestError}
				{validationStatus === 'maxAgeExceeded' && labels.WidgetMaxAgeRequestError}
			</ErrorMessage>
		</>
	);
};

AgeYearInput.displayName = 'AgeYearInput';
export default AgeYearInput;

const getCalculatedAge = (value: string, ageUnit: AgeUnit, labels: AgeYearLabels): CalculatedAge | undefined => {
	const intValue = parseInt(value, 10);

	if (isNaN(intValue)) {
		return undefined;
	}

	let ageInYears: number | undefined = undefined;
	let displayAgeUnit = '';

	switch (ageUnit) {
		case AgeUnit.Year:
			ageInYears = intValue;
			displayAgeUnit = labels.WidgetDisplayAgeYearsUnit;
			break;
		case AgeUnit.Month:
			ageInYears = intValue / 12;
			if (intValue > 1) {
				displayAgeUnit = labels.WidgetDisplayAgeMonthsUnit;
			} else {
				displayAgeUnit = labels.WidgetDisplayAgeMonthUnit;
			}
			break;
		case AgeUnit.Week:
			ageInYears = intValue / 52;
			if (intValue > 1) {
				displayAgeUnit = labels.WidgetDisplayAgeWeeksUnit;
			} else {
				displayAgeUnit = labels.WidgetDisplayAgeWeekUnit;
			}
			break;
	}

	return {
		age: ageInYears.toFixed(3),
		ageInYears: ageInYears,
		displayAge: `${intValue} ${displayAgeUnit}`
	};
};
