import { useCallback } from 'react';

import { useStateContext } from '../useStateContext';
import { CallConsultationPreparationGetNext } from '../../services';
import type { ActivityStep, ActivityStepConsultationPreparation, ActivityStepConsultationPreparationQuestion } from '../../../models';

export type UseNextStepConsultationPreparationResultType = (
	currentStep: ActivityStepConsultationPreparation | undefined,
	questionId: number | undefined,
	answer: number | undefined,
	answerChoices: number[] | undefined,
	answerText: string | undefined,
	urgence: string,
	empathy: string | undefined,
	showImageField: boolean,
	questions: ActivityStepConsultationPreparationQuestion[]
) => Promise<ActivityStep>;

export const useNextStepConsultationPreparation: () => UseNextStepConsultationPreparationResultType = () => {
	const [{ session, settings }] = useStateContext();

	return useCallback(
		async (currentStep, questionId, answer, answerChoices, answerText, urgence, empathy, showImageField, questions): Promise<ActivityStep> => {
			const lastQuestion = questions[questions.length - 1];
			lastQuestion.answer = {
				answerId: answer,
				answerChoices,
				answerText
			};

			let nextQuestion;
			if (questionId && answer) {
				nextQuestion = await CallConsultationPreparationGetNext(settings.ApiKey, {
					sessionId: session.id,
					sessionToken: session.token,
					questionId: questionId,
					answerId: answer,
					answerChoices: answerChoices,
					answerText: answerText,
					languageCode: settings.selectedLanguage.code
				});
				if (nextQuestion?.question) {
					// TODO: could each question have its own empathy?
					questions.push({
						type: 'consultationPreparationQuestion' as const,
						question: nextQuestion.question,
						empathy
					});
				}
			}

			if (nextQuestion?.question || !currentStep) {
				return {
					step: 0,
					id: currentStep?.id ?? `consultationPreparation-${questionId ?? lastQuestion.question.id}`,
					type: 'consultationPreparation',
					questions: questions,
					urgence: urgence,
					showImageField
				};
			} else {
				return {
					step: 0,
					id: 'consultationPreparation-requestCallback',
					type: 'requestCallback',
					urgence: urgence,
					showImageField
				};
			}
		},
		[session, settings.ApiKey, settings.selectedLanguage]
	);
};
