import React, { useEffect, useMemo, useCallback } from 'react';

import ActivityDefault from '../ActivityDefault/ActivityDefault';
import ActivityBodyArea from '../ActivityBodyArea/ActivityBodyArea';
import ActivityGPPost from '../ActivityGPPost/ActivityGPPost';
import ActivityGender from '../ActivityGender/ActivityGender';
import ActivityAge from '../ActivityAge/ActivityAge';
import ActivityDirectTriage from '../ActivityDirectTriage/ActivityDirectTriage';
import ActivitySearchComplaintSelector from '../ActivitySearchComplaintSelector/ActivitySearchComplaintSelector';
import ActivitySearchTriage from '../ActivitySearchTriage/ActivitySearchTriage';
import ActivityNoNextStepFound from '../ActivityNoNextStepFound/ActivityNoNextStepFound';
import ActivityNoTriageFound from '../ActivityNoTriageFound/ActivityNoTriageFound';
import ActivityAdvice from '../ActivityAdvice/ActivityAdvice';
import ActivityFeedback from '../ActivityFeedback/ActivityFeedback';
import ConsultationPreparation from '../ConsultationPreparation/ConsultationPreparation';
import Confirmation from '../Confirmation/Confirmation';
import SurveyEnd from '../SurveyEnd/SurveyEnd';
import SurveyInquiry from '../SurveyInquiry/SurveyInquiry';
import SurveyQuestion from '../SurveyQuestion/SurveyQuestion';
import ProcedureContactRequest from '../ProcedureContactRequest/ProcedureContactRequest';
import CallbackRequestScheduler from '../CallbackRequestScheduler/CallbackRequestScheduler';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';
import { CallStartSession, StartSessionOutput } from '../../helpers/services/CallStartSession';
import { useDetermineNextStep } from '../../helpers/hooks/nextStep/useDetermineNextStep';
import { getLabel } from '../../helpers/constants/getLabels';
import SectionTriage from '../SectionTriage';
import SectionBodyArea from '../SectionBodyArea/SectionBodyArea';
import SectionComplaint from '../SectionComplaint/SectionComplaint';
import type {
	ActivityAnswer,
	ActivityStep,
	ActivityStepAbcdTriage,
	ActivityStepAdvice,
	ActivityStepAgeSelector,
	ActivityStepTriageGroupBodyAreaSelector,
	ActivityStepCallbackRequestScheduler,
	ActivityStepBodyAreaSelector,
	ActivityStepComplaintSelector,
	ActivityStepConsultationPreparation,
	ActivityStepGenderSelector,
	ActivityStepDirectTriage,
	ActivityStepNormalTriage,
	ActivityStepRequestCallback,
	ActivityStepRequestCallbackEnd,
	ActivityStepStartWithAbcdTriage,
	ActivityStepSurveyEnd,
	ActivityStepSurveyInquiry,
	ActivityStepSurveyQuestion,
	ActivityStepAskGPPost
} from '../../models';
import { CallPostSessionLogAdviceBack, CallPostTriageProgress, PostTriageProgressInput } from '../../helpers/services';

interface SectionProps {
	currentSection: ActivityStep;
	isFirstActivity: boolean;
	handleNext: () => void;
	handlePrevious: () => void;
	setHandlePrevious: React.Dispatch<() => void>;
	setHandleNext: React.Dispatch<() => void>;
	setDisablePrevious: React.Dispatch<boolean>;
	setDisableNext: React.Dispatch<boolean>;
	setIsLoading: React.Dispatch<boolean>;
}
export interface ActivityProps {
	id: string;
	modalOpen?: boolean;
	disabled: boolean;
	isFirstActivity: boolean;
	isLastActivity: boolean;
	handleNext: () => void;
	handlePrevious: () => void;
	handleActivityResponse: (answer: ActivityAnswer, triageProgress?: PostTriageProgressInput) => Promise<false | undefined>;
	setHandlePrevious: React.Dispatch<() => void>;
	setHandleNext: React.Dispatch<() => void>;
	setDisablePrevious: React.Dispatch<boolean>;
	setDisableNext: React.Dispatch<boolean>;
	setIsLoading: React.Dispatch<boolean>;
}

function didAnswerChange(a: ActivityStep, b: ActivityStep, askForBirthday: boolean) {
	if (a.type === 'ageSelector') {
		const getAnswerField = (step: ActivityStepAgeSelector) => (askForBirthday ? step.answer?.dob : step.answer?.age);
		return JSON.stringify(getAnswerField(a)) !== JSON.stringify(getAnswerField(b as ActivityStepAgeSelector));
	}

	return JSON.stringify(a.answer) !== JSON.stringify(b.answer);
}

const Activity = (props: SectionProps) => {
	const [
		{
			modal,
			profile,
			session,
			settings,
			conversation,
			sectionIndex,
			dialog: { confirm }
		},
		dispatch
	] = useStateContext();
	const handleToggleModal = useToggleModal();
	const determineNextStep = useDetermineNextStep();

	const { currentSection } = props;

	const isLastActivity = conversation[conversation.length - 1] === currentSection;

	const isSectionDisabled = useMemo(() => {
		const disabledIndex = Math.max(
			conversation.findIndex((c) => c.type === 'requestCallback' && c.answer),
			conversation.findIndex((c) => c.type === 'callbackRequestScheduler' && c.answer)
		);

		if (disabledIndex !== -1) {
			return sectionIndex <= disabledIndex;
		}

		return false;
	}, [conversation, sectionIndex]);

	useEffect(() => {
		if (!currentSection.visited) {
			const updatedStep = {
				...currentSection,
				visited: true
			};
			dispatch({
				type: 'conversation/updateStep',
				step: updatedStep
			});
		}
	}, [currentSection, dispatch]);

	const continueConversation = useCallback(
		async (activityStep: Readonly<ActivityStep>) => {
			const nextStep = await determineNextStep(activityStep);
			if (nextStep) {
				dispatch({
					type: 'conversation/setNextStep',
					currentStepId: activityStep.id,
					step: nextStep
				});
			}
		},
		[determineNextStep, dispatch]
	);

	const shouldConfirmChange = useCallback(
		(currentIndex: number) => {
			const nextSection = conversation.at(currentIndex + 1);
			if (!nextSection) {
				return false;
			}
			if (!nextSection.answer) {
				// If the next section is unanswered return true if it has an answered question
				return 'questions' in nextSection && !!nextSection.questions[0].answer;
			} else {
				return true;
			}
		},
		[conversation]
	);

	const confirmChange = useCallback(async () => {
		return await confirm({
			header: getLabel('TriageChangePreviousQuestionAnswerHeader', settings.applicationTexts),
			message: getLabel('TriageChangePreviousQuestionAnswerMessage', settings.applicationTexts),
			cancelLabel: getLabel('QuestionDefaultNoLabel', settings.applicationTexts, true),
			confirmLabel: getLabel('QuestionDefaultYesLabel', settings.applicationTexts, true),
			buttonLayout: 'horizontal'
		});
	}, [settings.applicationTexts, confirm]);

	const handleActivityResponse = useCallback(
		async (answer: ActivityAnswer, triageProgress?: PostTriageProgressInput) => {
			let startSessionResult: StartSessionOutput | undefined;

			if (!modal.open) {
				startSessionResult = await CallStartSession(settings.ApiKey, {
					widgetType: settings.widgetType,
					restart: false,
					externalId: profile.externalId,
					phoneNumber: profile.phoneNumber,
					languageCode: settings.selectedLanguage.code
				});

				if (!startSessionResult) {
					console.error('There was an error starting a session');
					return;
				}

				dispatch({
					type: 'updateSession',
					session: {
						id: startSessionResult.sessionId,
						token: startSessionResult.sessionToken
					}
				});

				void handleToggleModal();
			}

			if (answer) {
				const conversationStepIndex = conversation.findIndex((step) => step.id === currentSection?.id);
				const conversationStep = conversation[conversationStepIndex];
				const isPreviousStep = conversationStep?.id !== conversation.at(-1)?.id;

				if (isPreviousStep) {
					if (didAnswerChange(conversationStep, { ...conversationStep, answer } as ActivityStep, settings.askForBirthday)) {
						if (!shouldConfirmChange(conversationStepIndex) || (await confirmChange())) {
							// No confirmation needed or user confirmed answer change
							// Throw away all next steps
							const updatedConversation = conversation.slice(0, conversationStepIndex + 1);

							if (conversation.some((c) => c.type === 'advice' && c.visited === true) && !updatedConversation.some((c) => c.type === 'advice')) {
								// LogAdviceBack when user has seen an advice and goes back to change an answer that wil remove the advice from the conversation
								await CallPostSessionLogAdviceBack(settings.ApiKey, {
									sessionId: session.id,
									sessionToken: session.token,
									sessionLogAdviceBack: {
										adviceBackClicked: true
									}
								});
							}

							dispatch({
								type: 'updateConversationActivities',
								conversation: updatedConversation
							});
						} else {
							// Answer change canceled
							return false;
						}
					} else {
						// Answer didn't change, no need to set an answer and add the next step
						return undefined;
					}
				}

				if (triageProgress) {
					void CallPostTriageProgress(settings.ApiKey, {
						...triageProgress,
						sessionId: startSessionResult?.sessionId ?? session.id,
						sessionToken: startSessionResult?.sessionToken ?? session.token
					});
				}

				dispatch({
					type: 'conversation/setStepAnswer',
					stepId: currentSection.id,
					answer: answer
				});

				const currentStep: ActivityStep = {
					...conversationStep
				};
				currentStep.answer = answer as typeof currentStep.answer;
				await continueConversation(currentStep);
			}
		},
		[
			modal.open,
			settings.ApiKey,
			profile.externalId,
			profile.phoneNumber,
			settings.selectedLanguage.code,
			settings.widgetType,
			settings.askForBirthday,
			dispatch,
			handleToggleModal,
			continueConversation,
			shouldConfirmChange,
			confirmChange,
			session,
			currentSection,
			conversation
		]
	);

	const passedProps: ActivityProps = {
		id: currentSection.id,
		modalOpen: modal.open ?? undefined,
		disabled: isSectionDisabled,
		isFirstActivity: props.isFirstActivity,
		isLastActivity: isLastActivity,
		handleNext: props.handleNext,
		handlePrevious: props.handlePrevious,
		handleActivityResponse: handleActivityResponse,
		setHandlePrevious: props.setHandlePrevious,
		setHandleNext: props.setHandleNext,
		setDisablePrevious: props.setDisablePrevious,
		setDisableNext: props.setDisableNext,
		setIsLoading: props.setIsLoading
	};

	return (
		<>
			{(currentSection.type &&
				{
					//welcomeMessage: <ActivityWelcome {...passedProps} />,
					genderSelector: <ActivityGender {...(currentSection as ActivityStepGenderSelector)} {...passedProps} />,
					ageSelector: (
						<ActivityAge
							{...(currentSection as ActivityStepAgeSelector)}
							currentStep={currentSection as ActivityStepAgeSelector}
							{...passedProps}
						/>
					),
					bodyAreaSelector: <SectionBodyArea {...passedProps} currentStep={currentSection as ActivityStepBodyAreaSelector} />,
					complaintSelector: <SectionComplaint {...passedProps} currentStep={currentSection as ActivityStepComplaintSelector} />,
					directTriage: <ActivityDirectTriage {...passedProps} currentStep={currentSection as ActivityStepDirectTriage} />,
					searchTriage: <ActivitySearchTriage {...currentSection} {...passedProps} />,
					searchComplaintSelector: <ActivitySearchComplaintSelector {...currentSection} {...passedProps} />,
					triageGroupBodyAreaSelector: <ActivityBodyArea {...(currentSection as ActivityStepTriageGroupBodyAreaSelector)} {...passedProps} />,
					askGPPost: <ActivityGPPost {...(currentSection as ActivityStepAskGPPost)} {...passedProps} />,
					triageNormal: <SectionTriage {...passedProps} currentStep={currentSection as ActivityStepNormalTriage} />,
					triageABCD: <SectionTriage {...passedProps} currentStep={currentSection as ActivityStepAbcdTriage} />,
					abcdTriage: <SectionTriage {...passedProps} currentStep={currentSection as ActivityStepStartWithAbcdTriage} />,
					advice: <ActivityAdvice {...(currentSection as ActivityStepAdvice)} {...passedProps} />,
					feedback: <ActivityFeedback {...currentSection} {...passedProps} />,
					noTriageFound: <ActivityNoTriageFound {...currentSection} {...passedProps} />,
					noNextStepFound: <ActivityNoNextStepFound {...currentSection} {...passedProps} />,
					consultationPreparation: <ConsultationPreparation currentStep={currentSection as ActivityStepConsultationPreparation} {...passedProps} />,
					surveyQuestion: <SurveyQuestion {...(currentSection as ActivityStepSurveyQuestion)} {...passedProps} />,
					surveyInquiry: <SurveyInquiry {...(currentSection as ActivityStepSurveyInquiry)} {...passedProps} />,
					surveyEnd: <SurveyEnd {...(currentSection as ActivityStepSurveyEnd)} {...passedProps} />,
					requestCallback: <ProcedureContactRequest {...(currentSection as ActivityStepRequestCallback)} {...passedProps} />,
					requestCallbackEnd: <Confirmation {...(currentSection as ActivityStepRequestCallbackEnd)} {...passedProps} />,
					callbackRequestScheduler: <CallbackRequestScheduler {...(currentSection as ActivityStepCallbackRequestScheduler)} {...passedProps} />
				}[currentSection.type]) || <ActivityDefault {...currentSection} {...passedProps} answer={currentSection.answer as string} />}

			{/* {process.env.NODE_ENV && process.env.NODE_ENV === 'development' && <div style={{ position: 'absolute', bottom: '0.5rem', left: '0.5rem' }}>{session}</div>} */}
		</>
	);
};

export default Activity;
