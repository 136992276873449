import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface ButtonProps {
	$toggle?: boolean;
	$show?: string;
}

export interface IconProps {
	$position?: 'before' | 'after';
}

export interface LoaderProps {
	$primary?: boolean;
}

const BaseButton = styled.button.attrs<ButtonProps>((props) => ({
	'aria-pressed': props.$toggle ? 'false' : undefined
}))<ButtonProps>`
	&&& {
		all: unset;
		cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
		color: ${(props) => (props.disabled ? props.theme.colors.disabled : props.theme.colors.accent)};
		border: 2px solid ${(props) => (props.disabled ? props.theme.colors.disabled : props.theme.colors.accent)};
		background: ${(props) => (props.disabled ? props.theme.colors.disabled : 'transparent')};
		padding: ${(props) => `${props.theme.spacings.small} ${props.theme.spacings.large}`} !important;
		outline: 0;
		font-weight: initial !important;
		text-transform: initial !important;
		transition: initial !important;
		font-size: initial !important;
		/* style buttons depending on list of button */
		border-radius: ${(props) => props.theme.radii.default};
		margin-right: 10px;
		display: ${(props) => (props.$show === 'desktopOnly' ? 'none' : 'block')};

		svg path {
			fill: ${(props) => (props.disabled ? props.theme.colors.disabled : props.theme.colors.accent)};
		}

		&:hover:not([disabled]),
		&:active:not([disabled]),
		&:focus {
			color: #fff !important;
			background-color: ${(props) => props.theme.colors.accent600};
			border-color: ${(props) => props.theme.colors.accent600};

			svg path {
				fill: #fff;
			}
		}

		@media ${(props) => props.theme.screens.medium} {
			display: block;
		}
	}
`;

const ActiveButton = styled(BaseButton).attrs<ButtonProps>((props) => ({
	'aria-pressed': props.$toggle ? 'true' : undefined
}))<ButtonProps>`
	&&& {
		background-color: ${(props) => props.theme.colors.accent};
		border-color: ${(props) => props.theme.colors.disabled};
		color: #fff;
		&[disabled] {
			background: ${(props) => props.theme.colors.accent};
			border-color: ${(props) => props.theme.colors.accent};
			color: #fff;
		}
	}
`;

const Icon = styled(FontAwesomeIcon)<IconProps>`
	${(props) => (props.$position === 'before' ? `margin-inline-end: ${props.theme.spacings.smallMedium};` : '')};
	${(props) => (props.$position === 'after' ? `margin-inline-start: ${props.theme.spacings.smallMedium};` : '')};
`;

const spin = keyframes`
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
`;

const Loader = styled.div<LoaderProps>`
	margin-inline-start: ${(props) => props.theme.spacings.smallMedium};
	border: 0.2em solid rgba(0, 0, 0, 0.1);
	border-top: 0.2em solid ${(props) => (props.$primary ? '#fff' : props.theme.colors.accent)};
	border-radius: 50%;
	width: calc(1.25 * ${(props) => props.theme.spacings.medium});
	height: calc(1.25 * ${(props) => props.theme.spacings.medium});
	animation: ${spin} 1s linear infinite;
`;

const BaseIconButton = styled.button.attrs<ButtonProps & LoaderProps & IconProps>((props) => ({
	'aria-pressed': props.$toggle ? 'false' : undefined
}))`
	&&& {
		all: unset;
		cursor: pointer;
		background: ${(props) => (props.$primary ? props.theme.colors.accent : 'transparent')};
		color: ${(props) => (props.$primary ? '#fff' : props.theme.colors.accent)};

		border: 2px solid ${(props) => props.theme.colors.accent};
		padding: 6px 16px !important;
		outline: 0;
		border-radius: ${(props) => props.theme.radii.default};
		font-weight: initial !important;
		text-transform: initial !important;
		transition: initial !important;
		font-size: initial !important;

		display: flex;
		align-items: center;

		${(props) => (props.$position === 'after' ? 'justify-content: space-between;' : '')};

		svg path {
			fill: ${(props) => (props.$primary ? '#fff' : props.theme.colors.accent)};
		}

		/* style buttons depending on list of button */
		border-radius: ${(props) => props.theme.radii.default};

		&[disabled] {
			cursor: default !important;
			border-color: ${(props) => props.theme.colors.disabled};
			color: ${(props) => props.theme.colors.disabled};
		}

		&:focus:not([disabled]),
		&:active:not([disabled]) {
			outline: 2px solid ${(props) => props.theme.colors.accent};
			outline-offset: 1px;
			box-shadow: unset;
		}

		&:hover:not([disabled]),
		&:active:not([disabled]) {
			color: ${(props) => (props.$primary ? '#fff' : props.theme.colors.accentDarker)} !important;
			svg path {
				fill: ${(props) => (props.$primary ? '#fff' : props.theme.colors.accentDarker)};
			}
			background-color: ${(props) => (props.$primary ? props.theme.colors.accentDarker : '#fff')};
			border-color: ${(props) => props.theme.colors.accentDarker};
		}
	}
`;

const ActiveIconButton = styled(BaseIconButton).attrs<ButtonProps>((props) => ({
	'aria-pressed': props.$toggle ? 'true' : undefined
}))`
	&&& {
		background: ${(props) => (props.$primary ? props.theme.colors.accent : '#fff')};
		color: ${(props) => (props.$primary ? '#fff' : props.theme.colors.accent)};
		&[disabled] {
			cursor: default !important;
			background: ${(props) => props.theme.colors.accent};
			border-color: ${(props) => props.theme.colors.accent};
			color: #fff;
			svg path {
				fill: ${(props) => (props.$primary ? '#fff' : props.theme.colors.accent)};
			}
		}

		svg path {
			fill: ${(props) => (props.$primary ? '#fff' : props.theme.colors.accent)};
		}
	}
`;

const S = {
	BaseButton: (props: ButtonProps) => <BaseButton {...props} data-minddclass="basebutton" />,
	ActiveButton: (props: ButtonProps) => <ActiveButton {...props} data-minddclass="activebutton" />,
	Icon,
	Loader,
	BaseIconButton: (props: ButtonProps & LoaderProps & IconProps) => <BaseIconButton {...props} data-minddclass="base-icon-button" />,
	ActiveIconButton: (props: ButtonProps & LoaderProps & IconProps) => <ActiveIconButton {...props} data-minddclass="active-icon-button" />
};

export default S;
