import React, { useState, useEffect, useLayoutEffect, useRef, FunctionComponent, useMemo } from 'react';

import { useTheme } from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRedo, faArrowUpRightFromSquare, faXmark, faChevronUp } from '@fortawesome/free-solid-svg-icons';

import { default as SA } from '../Activity/Activity.styled';
import S from './ActivityAdvice.styled';
import SD from './drawerStyles';

import Button from '../Button';

import MinddLogoLoader from '../MinddLogoLoader/MinddLogoLoader';
import ProcedurePostcodeRequest from '../ProcedurePostcodeRequest/ProcedurePostcodeRequest';
import SurveyIntro from '../SurveyIntro/SurveyIntro';

import { useStateContext } from '../../helpers/hooks/useStateContext';
import { useToggleModal } from '../../helpers/hooks/useToggleModal';
import { useNextStepCallbackRequest } from '../../helpers/hooks/nextStep/useNextStepCallbackRequest';
import { getLabel } from '../../helpers/constants/getLabels';
import { renderMarkdown } from '../../helpers/support/renderMarkdown';
import { CallPostCallButtonClicked, CallPostSessionLogProperties } from '../../helpers/services';

import DownloadButton from '../DownloadButton/DownloadButton';
import WidgetDrawer from '../WidgetDrawer/WidgetDrawer';
import { useWindowSize } from '../../helpers/hooks/useWindowSize';
import ContactRequestContainer from './ContactRequestContainer';
import PhoneNumberList from './PhoneNumberList';
import type { ActivityAnswerAdvice, ActivityAnswerSurveyQuestion, Advice, AdvicePhoneNumber } from '../../models';
import { ActivityProps } from '../Activity';
import ProcedureSendEmailOrSmsAdvice from '../ProcedureSendEmailOrSmsAdvice/ProcedureSendEmailOrSmsAdvice';
import { useResetWidget } from '../../helpers/hooks/useResetWidget';
import ExpandableDiv from '../ExpandableDiv/ExpandableDiv';

import U1 from '../../assets/U1.svg';
import U2 from '../../assets/U2.svg';
import U3 from '../../assets/U3.svg';
import U4 from '../../assets/U4.svg';
import U5 from '../../assets/U5.svg';
import { TRANSITION_TIMING } from '../ExpandableDiv/ExpandableDiv.styled';

const HEADER_HEIGHT = 224;
const HEADER_HEIGHT_DESKTOP = 186;
const LOADER_TIMEOUT = 4000;
const TOC_HEADER_HEIGHT = 55;

const urgencyImages = {
	U5,
	U4,
	U3,
	U2,
	U1
} as { [key: string]: string };

interface ActivityAdviceProps extends ActivityProps {
	advice: Advice;
	callButtonPhoneNumbers: AdvicePhoneNumber[];
	visited?: boolean;
	answer?: ActivityAnswerAdvice;
}

const ActivityAdvice: FunctionComponent<ActivityAdviceProps> = (props) => {
	const [{ conversation, settings, session, refs }, dispatch] = useStateContext();

	const handleNextStepCallbackRequest = useNextStepCallbackRequest();
	const [showFollowupRestart, setShowFollowupRestart] = useState(true);
	const [showActivityGroup, setShowActivityGroup] = useState(props.visited ?? false);
	const [showPhoneNumberPanel, setShowPhoneNumberPanel] = useState(false);
	const adviceRef = useRef<HTMLDivElement>() as React.MutableRefObject<HTMLDivElement>;
	const adviceNavRef = useRef<HTMLOListElement>() as React.RefObject<HTMLOListElement>;
	const [currentNode, setCurrentNode] = useState('');
	const adviceParacetamolRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const procedureContactRequestRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const contactRequestRef = useRef<HTMLDivElement>();
	const procedureRedirectToDoctorPageRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const procedureAskForZipCodeRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const procedureSendEmailOrSmsAdviceRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const procedureSurveyRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const lastActivityRef = useRef<HTMLHeadingElement>() as React.RefObject<HTMLHeadingElement>;
	const [isContactRequestForMobileVisible, setIsContactRequestForMobileVisible] = useState(false);
	const headerRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const characteristicRef = useRef<HTMLDivElement>() as React.RefObject<HTMLDivElement>;
	const [chips] = useState<Set<string>>(new Set<string>());
	const resetWidget = useResetWidget();
	const { advice } = props;
	const toggleModal = useToggleModal();
	const [toggleToC, setToggleToC] = useState<() => void>();

	// We only show the phone numbers that match the advice referral and urgence
	const activePhoneNumbers = Array.isArray(props.callButtonPhoneNumbers) ? props.callButtonPhoneNumbers : [];
	const showPhoneNumbers = settings.callButtonActive && activePhoneNumbers.length >= 1;
	// Survey disabled, because it doesn't work and no one knows whether it is still being used
	// const showSurvey = !advice.startContactRequestProcedure && !advice.startZorgMailRequestProcedure && settings.survey.active && settings.survey.surveyId > 0;
	const showSurvey = false;

	const startContactRequest = advice.startContactRequestProcedure || advice.startZorgMailRequestProcedure;

	const conversationHasRequestCallback = conversation.some((c) => c.type === 'requestCallback' || c.type === 'consultationPreparation');

	const themeContext = useTheme();
	const isMobile = useWindowSize().width < themeContext.breakpoints.medium;
	const drawerContainerRef = useRef(null);

	const handleMoreInfo = (url?: string) => {
		void CallPostSessionLogProperties(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			sessionLogProperties: [
				{
					sessionLogPropertyName: 'AdviceClicked',
					sessionLogPropertyValue: true
				}
			]
		});

		window.open(url, '_blank');
	};

	const handleParacetamolLink = (url?: string) => {
		window.open(url, '_blank');
	};

	const handleCall = async (phoneNumber = settings.phoneNumber || '') => {
		await CallPostCallButtonClicked(settings.ApiKey, {
			sessionId: session.id,
			sessionToken: session.token,
			phoneNumber: phoneNumber
		});

		window.open(`tel:${phoneNumber}`, '_self');
	};

	const handleRedirectToUrl = () => {
		if (settings.redirectToDoctorPageUrl && settings.redirectToDoctorPageUrl.trim() !== '') window.open(settings.redirectToDoctorPageUrl, '_blank');
	};

	const handleSurveyAction = (answer: ActivityAnswerSurveyQuestion) => {
		setShowFollowupRestart(false);
		return props.handleActivityResponse({ ...props.answer, surveyAnswerId: answer.answerId, surveyAnswerText: answer.answerText });
	};

	const handleRestart = () => {
		void resetWidget(true).catch(console.error);
	};

	useEffect(() => {
		if (lastActivityRef.current && props.isLastActivity && props.modalOpen) {
			lastActivityRef.current.focus();
		}
	}, [lastActivityRef.current]);

	useEffect(() => {
		if (!showActivityGroup) {
			// Start the timer after 4 seconds
			const timeoutId = setTimeout(() => {
				setShowActivityGroup(true);
			}, LOADER_TIMEOUT);

			if (showPhoneNumbers) {
				void CallPostSessionLogProperties(settings.ApiKey, {
					sessionId: session.id,
					sessionToken: session.token,
					sessionLogProperties: [
						{
							sessionLogPropertyName: 'CallButtonShown',
							sessionLogPropertyValue: true
						}
					]
				});
			}

			return () => clearTimeout(timeoutId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (showActivityGroup) {
			if (settings.target instanceof Element) {
				settings.target.dispatchEvent(
					new CustomEvent('minddWidgetAdviceShown', {
						bubbles: true,
						detail: { adviceId: advice.id }
					})
				);
			}

			if (typeof settings.onAdviceShown === 'function') {
				settings.onAdviceShown.call(null, advice.id);
			}
		}
	}, [showActivityGroup, advice.id, settings.target, settings.onAdviceShown]);

	useLayoutEffect(() => {
		if (procedureContactRequestRef.current && refs.widget?.current) {
			const widget = refs.widget.current;

			const handleScroll = () => {
				if (procedureContactRequestRef.current) {
					// Show when we scroll to bottom of advice.characteristic
					const contactRequestRect = procedureContactRequestRef.current.getBoundingClientRect();
					const showContactRequest = contactRequestRect.top < window.innerHeight;

					if (!showContactRequest) {
						setIsContactRequestForMobileVisible(false);
					} else {
						setIsContactRequestForMobileVisible(true);
					}
				}

				// TODO: this list should be created and stored after the first render,
				// not on every scroll event
				const nodes =
					(adviceNavRef.current &&
						Array.from(adviceNavRef.current.children)
							.map((child) => document.getElementById(child.getAttribute('data-node') ?? ''))
							.filter((node) => (isMobile ? node?.id !== 'ProcedureContactRequest' : true))
							.sort((a, b) => (b?.getBoundingClientRect().top ?? 0) - (a?.getBoundingClientRect().top ?? 0))) ??
					[];
				// Header height is the height of the widget header
				// Plus 1 rem spacing
				// On mobile, plus height of the table of contents when not open
				const headerHeight = isMobile ? HEADER_HEIGHT : HEADER_HEIGHT_DESKTOP;
				const currentNode = nodes.find((node) => (node?.getBoundingClientRect().top ?? Infinity) < headerHeight) ?? nodes.at(-1);
				setCurrentNode(currentNode?.id ?? '');
			};
			// Initial check
			handleScroll();
			// Attach the scroll event listener
			widget.addEventListener('scroll', handleScroll);
			// Cleanup: Remove the scroll event listener when the component unmounts
			return () => {
				widget.removeEventListener('scroll', handleScroll);
			};
		}
	}, [refs.widget, adviceRef.current]);

	const handleRequestCallback = async () => {
		if (advice.startContactRequestProcedure) {
			void CallPostSessionLogProperties(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				sessionLogProperties: [{ sessionLogPropertyName: 'StartCallbackProcedureClicked', sessionLogPropertyValue: true }]
			});
		} else if (advice.startZorgMailRequestProcedure) {
			void CallPostSessionLogProperties(settings.ApiKey, {
				sessionId: session.id,
				sessionToken: session.token,
				sessionLogProperties: [{ sessionLogPropertyName: 'StartZorgMailProcedureClicked', sessionLogPropertyValue: true }]
			});
		}

		if (conversationHasRequestCallback) {
			props.handleNext();
			return;
		}

		const nextStep = await handleNextStepCallbackRequest(advice);
		dispatch({
			type: 'conversation/addStep',
			step: nextStep
		});

		if (nextStep.type === 'consultationPreparation') {
			void props.handleActivityResponse({ ...props.answer, isConsultationPreparationStarted: true });
		} else if (nextStep.type === 'callbackRequestScheduler') {
			void props.handleActivityResponse({ ...props.answer, isRequestCallbackStarted: true });
		} else {
			void props.handleActivityResponse({ ...props.answer });
		}

		props.handleNext();
	};

	const handleOnReleaseDrawer = (event: React.PointerEvent<HTMLDivElement>, open: boolean) => {
		// drawer can be closed by dragging it down, so for consistent functionality this must be added
		if (!open && showPhoneNumberPanel) {
			setShowPhoneNumberPanel(false);
		}
	};

	const scrollTo = async (ref?: React.RefObject<HTMLElement>, anchor?: string) => {
		if (isMobile) {
			toggleToC?.();
			// We need to wait until the Table of Contents has finished collapsing
			// Otherwise the browser doesn't know where to scroll to
			// Adding small delta to ensure transition is finished before we scroll
			await new Promise((r) => setTimeout(r, (TRANSITION_TIMING as number) + 5));
		}
		const element = ref?.current ?? document.getElementById(anchor ?? '');
		// Add extra 55 pixels on mobile to account for height of sticky Table of Contents element
		const adviceHeaderHeight = isMobile ? TOC_HEADER_HEIGHT : 0;
		const scrollMarginTop = `${adviceHeaderHeight}px`;

		if (ref) {
			element && (element.style.scrollMarginTop = scrollMarginTop);
			element?.scrollIntoView();
		} else {
			// If we scroll to an anchor, we are scrolling within advice.characteristic
			// So we set the scrollMarginTop of the advice.characteristic wrapper
			// And then we scroll the advice.characteristic wrapper into view
			element && characteristicRef.current && (characteristicRef.current.style.scrollMarginTop = `${adviceHeaderHeight - element.offsetTop + 8}px`);
			characteristicRef.current?.scrollIntoView();
		}
	};

	const navItems = useMemo(
		() =>
			[
				advice.characteristic ? (
					<S.NavItem
						key="nav-our-advice"
						onClick={() => void scrollTo(characteristicRef)}
						data-node={getLabel('AdviceOnsAdvies', settings.applicationTexts, true)}
						className={currentNode === getLabel('AdviceOnsAdvies', settings.applicationTexts, true) ? 'active' : ''}
					>
						{getLabel('AdviceOnsAdvies', settings.applicationTexts, true)}
					</S.NavItem>
				) : undefined,
				chips.size > 0
					? Array.from(chips.values()).map((chip) => (
							<S.NavItem
								key={`nav-${chip}`}
								onClick={() => void scrollTo(undefined, chip)}
								data-node={chip}
								className={currentNode === chip ? 'active' : ''}
							>
								{chip}
							</S.NavItem>
					  ))
					: undefined,
				advice.showParacetamolLink ? (
					<S.NavItem
						key="nav-paracetamol"
						onClick={() => void scrollTo(adviceParacetamolRef)}
						data-node="AdviceParacetamol"
						className={currentNode === 'AdviceParacetamol' ? 'active' : ''}
					>
						{getLabel('AdviceTableOfContentsParacetamol', settings.applicationTexts)}
					</S.NavItem>
				) : undefined,
				advice.startContactRequestProcedure || showPhoneNumbers ? (
					<S.NavItem
						key="nav-contact"
						onClick={() => void scrollTo(procedureContactRequestRef)}
						data-node="ProcedureContactRequest"
						className={currentNode === 'ProcedureContactRequest' ? 'active' : ''}
					>
						{getLabel('AdviceTableOfContentsContactRequest', settings.applicationTexts)}
					</S.NavItem>
				) : undefined,
				advice.startRedirectToDoctorPageProcedure ? (
					<S.NavItem
						key="nav-redirect"
						onClick={() => void scrollTo(procedureRedirectToDoctorPageRef)}
						data-node="ProcedureRedirectToDoctorPage"
						className={currentNode === 'ProcedureRedirectToDoctorPage' ? 'active' : ''}
					>
						{getLabel('ActivityAdviceNavigationRedirectToDoctorPage', settings.applicationTexts)}
					</S.NavItem>
				) : undefined,
				settings.askForZipCode ? (
					<S.NavItem
						key="nav-zipcode"
						onClick={() => void scrollTo(procedureAskForZipCodeRef)}
						data-node="ProcedureAskForZipCode"
						className={currentNode === 'ProcedureAskForZipCode' ? 'active' : ''}
					>
						{getLabel('AdviceTableOfContentsAskForZipCode', settings.applicationTexts)}
					</S.NavItem>
				) : undefined,
				settings.sendEmailOrSmsAdviceActive ? (
					<S.NavItem
						key="nav-summary"
						onClick={() => void scrollTo(procedureSendEmailOrSmsAdviceRef)}
						data-node="ProcedureSendEmailOrSmsAdvice"
						className={currentNode === 'ProcedureSendEmailOrSmsAdvice' ? 'active' : ''}
					>
						{getLabel('AdviceTableOfContentsSendEmailOrSmsAdvice', settings.applicationTexts)}
					</S.NavItem>
				) : undefined,
				showSurvey ? (
					<S.NavItem
						key="nav-survey"
						onClick={() => void scrollTo(procedureSurveyRef)}
						data-node="ProcedureSurvey"
						className={currentNode === 'ProcedureSurvey' ? 'active' : ''}
					>
						{getLabel('AdviceTableOfContentsSurvey', settings.applicationTexts)}
					</S.NavItem>
				) : undefined
			].filter((navItem) => !!navItem),
		[
			advice.characteristic,
			Array.from(chips.values()).length,
			advice.showParacetamolLink,
			advice.startContactRequestProcedure,
			showPhoneNumbers,
			advice.startRedirectToDoctorPageProcedure,
			settings.askForZipCode,
			settings.sendEmailOrSmsAdviceActive,
			showSurvey,
			settings.applicationTexts,
			currentNode,
			toggleToC
		]
	);

	return (
		<>
			{showActivityGroup ? (
				<>
					<S.ActivityGroup
						style={{ paddingBottom: contactRequestRef.current?.scrollHeight ?? 0 }}
						ref={adviceRef}
						data-minddclass="adviceactivitygroup"
					>
						{/* General Advice */}
						<S.GeneralAdvice ref={headerRef}>
							{/* Header: Mobile only */}
							<S.AdviceHeader>
								{isMobile ? (
									<S.AdviceHeaderButton onClick={handleRestart}>
										<FontAwesomeIcon icon={faRedo} />
										{getLabel('AdviceButtonLabelRestart', settings.applicationTexts, true)}
									</S.AdviceHeaderButton>
								) : (
									<div />
								)}
								<div style={{ display: 'flex', gap: themeContext.spacings.small}}>
									{!isMobile && (
										<S.AdviceHeaderButton onClick={handleRestart}>
											<FontAwesomeIcon icon={faRedo} />
											{getLabel('AdviceButtonLabelRestart', settings.applicationTexts, true)}
										</S.AdviceHeaderButton>
									)}
									<S.AdviceHeaderButton onClick={() => void toggleModal(true)}>
										{getLabel('AdviceButtonLabelClose', settings.applicationTexts, true)}
										<FontAwesomeIcon icon={faXmark} />
									</S.AdviceHeaderButton>
								</div>
							</S.AdviceHeader>
							<S.GeneralAdviceAdvice>
								<S.GeneralAdviceAdviceContent>
									<S.AdviceTitle>{renderMarkdown(advice.title || '')}</S.AdviceTitle>
									<S.AdviceIntro>{advice.adviceIntro && <p>{renderMarkdown(advice.adviceIntro)}</p>}</S.AdviceIntro>
								</S.GeneralAdviceAdviceContent>

								<S.ProfileImageWrapper>
									{/* Dit is tijdelijk for preview release, later moet deze werwijderd worden */}
									{process.env.REACT_APP_ENV === 'Development' ? (
										<S.ProfileImage $profileImage={urgencyImages[advice.urgence]} />
									) : (
										<S.ProfileImage
											$profileImage={`https://${process.env.REACT_APP_PUBLICEMBEDDOMAIN!}/advice/${advice.urgence}.svg`}
										/>
									)}
								</S.ProfileImageWrapper>
							</S.GeneralAdviceAdvice>
						</S.GeneralAdvice>

						<S.AdviceContent>
							{navItems.length > 2 ? (
								<S.TableOfContents>
									<ExpandableDiv
										title={getLabel('AdviceTableOfContentsHeader', settings.applicationTexts, true)}
										disabled={!isMobile}
										noBorder
										defaultExpanded={!isMobile}
										style={{ padding: `0 0 0 ${themeContext.spacings.extraSmall}` }}
										setToggle={setToggleToC}
									>
										<S.Nav ref={adviceNavRef}>{navItems}</S.Nav>
									</ExpandableDiv>
								</S.TableOfContents>
							) : (
								<div style={{ display: 'flex', flex: '1' }} />
							)}

							<S.Advice>
								{/* Additional Advice */}
								{advice.additionalAdviceChannel && advice.additionalAdviceChannel !== '' && (
									<S.ActivityBubble>
										<div id="additional-advice">{renderMarkdown(advice.additionalAdviceChannel)}</div>
									</S.ActivityBubble>
								)}

								{/* Advice Characteristic */}
								<S.AdviceCharacteristicBlock ref={characteristicRef} id={getLabel('AdviceOnsAdvies', settings.applicationTexts, true)}>
									{renderMarkdown(advice.characteristic ?? '', {
										Collapsible: {
											component: (props: any) => {
												chips.add(props.title);
												return (
													<ExpandableDiv
														className="collapsible"
														defaultExpanded
														title={props.title}
														id={props.title}
														disabled={!isMobile}
													>
														{props.children}
													</ExpandableDiv>
												);
											}
										}
									})}
								</S.AdviceCharacteristicBlock>

								{/* Meer informatie */}
								{advice.linkUrl && (
									<S.ActivityBubble>
										<SA.BubbleSubTitle>{getLabel('AdviceMeerInformatiePrefix', settings.applicationTexts, true)}</SA.BubbleSubTitle>
										<S.AdviceLink href="#" onClick={() => handleMoreInfo(advice.linkUrl)}>
											<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
											<span>
												{getLabel('AdviceMeerInformatiePrefix', settings.applicationTexts)} {advice.linkTitle}
											</span>
										</S.AdviceLink>
									</S.ActivityBubble>
								)}

								{/* Paracetamol Advice */}
								{advice.showParacetamolLink && (
									<S.ActivityBubble ref={adviceParacetamolRef} id="AdviceParacetamol">
										{advice.showParacetamolLink && advice.paracetamolAdvice && (
											<S.ParacetamolAdviceBlock>
												<ExpandableDiv
													defaultExpanded
													title={getLabel('AdviceParacetamolAdvies', settings.applicationTexts, true)}
													disabled={!isMobile}
												>
													{renderMarkdown(advice.paracetamolAdvice)}
												</ExpandableDiv>
											</S.ParacetamolAdviceBlock>
										)}
									</S.ActivityBubble>
								)}

								{/* Paracetamol link */}
								{advice.showParacetamolLink && advice.paracetamolAdviceLinkUrl && (
									<S.ActivityBubble>
										<SA.BubbleSubTitle>{getLabel('AdviceMeerInformatiePrefix', settings.applicationTexts, true)}</SA.BubbleSubTitle>
										<S.AdviceLink href="#" onClick={() => handleParacetamolLink(advice.paracetamolAdviceLinkUrl)}>
											<FontAwesomeIcon icon={faArrowUpRightFromSquare} />
											<span>
												{getLabel('AdviceMeerInformatiePrefix', settings.applicationTexts)} {advice.paracetamolAdviceLinkTitle}
											</span>
										</S.AdviceLink>
									</S.ActivityBubble>
								)}

								{/* Procedure: Contact Verzoek, ZorgMail Procedure */}
								{(advice.startContactRequestProcedure || advice.startZorgMailRequestProcedure || showPhoneNumbers) && (
									<S.ActivityBubbleContactRequestProcedure ref={procedureContactRequestRef} id="ProcedureContactRequest">
										<ContactRequestContainer
											isVisible={isContactRequestForMobileVisible}
											// @ts-expect-error typescript doesn't like refs
											ref={contactRequestRef}
										>
											{advice.startContactRequestProcedure && (
												<div>{renderMarkdown(settings.contactOptions.labels.callbackRequestIntro || '')}</div>
											)}

											{showPhoneNumbers && !advice.startContactRequestProcedure && (
												<div>{renderMarkdown(settings.contactOptions.labels.directCallOptionIntro || '')}</div>
											)}

											<S.ActivityActionsContactButtons>
												{startContactRequest && (
													<S.CallbackRequestButton disabled={props.disabled} onClick={() => void handleRequestCallback()}>
														{renderMarkdown(
															(advice.startContactRequestProcedure
																? settings.contactOptions.labels.callbackRequestButton
																: settings.contactOptions.labels.zorgMailRequestButton) || ''
														)}
													</S.CallbackRequestButton>
												)}

												{showPhoneNumbers && (
													<S.ShowPhoneNumbersButton onClick={() => setShowPhoneNumberPanel(!showPhoneNumberPanel)}>
														{renderMarkdown(settings.contactOptions.labels.directCallButtonText || '')}
														<FontAwesomeIcon
															icon={faChevronUp}
															size="xs"
															rotation={isMobile !== showPhoneNumberPanel ? undefined : 180}
														/>
													</S.ShowPhoneNumbersButton>
												)}
											</S.ActivityActionsContactButtons>
										</ContactRequestContainer>

										{/* mobile */}
										{isMobile && showPhoneNumbers && (
											<WidgetDrawer
												ref={drawerContainerRef}
												isOpen={showPhoneNumberPanel}
												onRelease={handleOnReleaseDrawer}
												container={drawerContainerRef.current}
											>
												<SD.DrawerHeader>
													<SD.DrawerBubbleSubTitle>
														{getLabel('phoneNumbersLabel', settings.applicationTexts)}
													</SD.DrawerBubbleSubTitle>
													<SD.CloseDrawerButton onClick={() => setShowPhoneNumberPanel(false)} variant="link">
														{getLabel('AdviceButtonLabelClose', settings.applicationTexts)}
													</SD.CloseDrawerButton>
												</SD.DrawerHeader>
												<SD.ScrollableContentWrapper data-vaul-no-drag>
													<PhoneNumberList
														handleCall={(phoneNumber) => void handleCall(phoneNumber)}
														activePhoneNumbers={activePhoneNumbers}
													/>
												</SD.ScrollableContentWrapper>
											</WidgetDrawer>
										)}

										{/* desktop */}
										{!isMobile && showPhoneNumbers && showPhoneNumberPanel && (
											<S.PhoneNumberPanelContainer>
												<SA.LineDivider />
												<S.PhoneNumberPanel>
													<S.PhoneNumberPanelLabel>
														{getLabel('phoneNumbersLabel', settings.applicationTexts)}
													</S.PhoneNumberPanelLabel>
													<PhoneNumberList
														handleCall={(phoneNumber) => void handleCall(phoneNumber)}
														activePhoneNumbers={activePhoneNumbers}
													/>
												</S.PhoneNumberPanel>
												<SA.LineDivider />
											</S.PhoneNumberPanelContainer>
										)}
									</S.ActivityBubbleContactRequestProcedure>
								)}

								{/* Procedure: Doorverwijzing naar URL */}
								{advice.startRedirectToDoctorPageProcedure && (
									<S.ActivityBubble ref={procedureRedirectToDoctorPageRef} id="ProcedureRedirectToDoctorPage">
										<SA.BubbleSubTitle>{getLabel('RedirectToDoctorPageProcedureIntro', settings.applicationTexts)}</SA.BubbleSubTitle>
										<Button $state={true} onClick={handleRedirectToUrl}>
											{getLabel('RedirectToDoctorPageProcedureButton', settings.applicationTexts)}
										</Button>
									</S.ActivityBubble>
								)}

								{/* Procedure: Postcode uitvragen */}
								{settings.askForZipCode && (
									<S.ActivityBubble ref={procedureAskForZipCodeRef} id="ProcedureAskForZipCode">
										<ProcedurePostcodeRequest />
									</S.ActivityBubble>
								)}

								{/* Procedure: Advies verzenden middels SMS of email */}
								{settings.sendEmailOrSmsAdviceActive && (
									<S.ActivityBubble ref={procedureSendEmailOrSmsAdviceRef} id="ProcedureSendEmailOrSmsAdvice">
										<SA.BubbleSubTitle>{getLabel('AdviceSendEmailOrSmsAdviceTitle', settings.applicationTexts)}</SA.BubbleSubTitle>
										<ProcedureSendEmailOrSmsAdvice />
									</S.ActivityBubble>
								)}

								{/* Procedure: Enquete */}
								{showSurvey && (
									<S.ActivityBubble ref={procedureSurveyRef} id="ProcedureSurvey">
										<SurveyIntro disabled={props.disabled} handleActivityResponse={handleSurveyAction} />
									</S.ActivityBubble>
								)}

								{/* Generiek advies outro */}
								{advice.adviceOutro && (
									<S.AdviceOutro>
										<p>{renderMarkdown(advice.adviceOutro)}</p>
									</S.AdviceOutro>
								)}

								{/* 'Gespreksverslag downloaden' */}
								{!settings.downloadConversationDisabled && (
									<div
										style={{
											marginBottom: themeContext.spacings.medium,
											display: 'flex',
											flexDirection: 'row',
											justifyContent: 'space-between'
										}}
									>
										<DownloadButton />
									</div>
								)}
							</S.Advice>
						</S.AdviceContent>
					</S.ActivityGroup>
				</>
			) : (
				<>
					<MinddLogoLoader />
					<img width={0} height={0} style={{ display: 'none' }} src={`https://${process.env.REACT_APP_PUBLICEMBEDDOMAIN!}/advice/${advice.urgence}.svg`} alt="" />
				</>
			)}
		</>
	);
};

export default ActivityAdvice;
