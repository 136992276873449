import styled from 'styled-components';

const Container = styled.div`
	width: 100%;

	@media ${(props) => props.theme.screens.medium} {
		width: auto;
	}

	.language-selector__control {
		width: 100%;
		@media ${(props) => props.theme.screens.medium} {
			width: auto;
		}
	}
`;

export default {
	Container
};
