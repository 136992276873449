import styled from 'styled-components';
import { default as SA } from '../Activity/Activity.styled';

interface ActivityComplaintBodyProps {
	disabled: boolean;
	$highlight?: number;
}

const ActivityBubble = styled(SA.ActivityBubble)`
	&&& {
		max-height: none;
	}
`;

const ActivityComplaintWrapper = styled.div`
	display: flex;
	flex-direction: column;
	margin-top: ${(props) => props.theme.spacings.medium};
`;

const Body = styled.div<ActivityComplaintBodyProps>`
	position: relative;
	max-width: 100%;

	width: 100%;
	max-height: 500px;
	height: 60vh;
	padding-inline-end: 0;
	display: flex;
	margin: ${(props) => props.theme.spacings.small} auto ${(props) => props.theme.spacings.small} auto;
	align-items: center;

	@media ${(props) => props.theme.screens.medium} {
		max-width: 250px;
		padding-inline-end: 1em;
	}

	g[data-bodyareaid]:focus {
		outline: none;

		path {
			opacity: ${(props) => !props.disabled && '0.4'};
		}
	}

	path[data-bodyareaid],
	g[data-bodyareaid] path {
		transition: opacity 0.15s ease-in;
		opacity: 0;
		fill: ${(props) => props.theme.colors.text900};
		cursor: ${(props) => !props.disabled && 'pointer'};

		&:focus,
		&:hover {
			opacity: ${(props) => !props.disabled && '0.4'};
			outline: none;
		}

		&:focus {
			outline: none;
		}
	}
	path[data-bodyareaid='${(props) => props.$highlight}'] {
		fill: ${(props) => props.theme.colors.accent};
		opacity: 0.4;
	}
	path[data-disabled],
	g[data-disabled] path {
		fill: ${(props) => props.theme.colors.text300};
		opacity: 0.2;
	}
	g[data-disabled] path[id='haar'],
	g[data-disabled] path[id='haarachter'] {
		fill: ${(props) => props.theme.colors.text300};
		opacity: 1;
	}

	path[id='haar'],
	path[id='haarachter'],
	g[data-bodyareaid] path[id='haar'],
	g[data-bodyareaid] path[id='haarachter'] {
		opacity: 1;
	}
	g[data-bodyareaid] path[id='haar']:hover,
	g[data-bodyareaid] path[id='haarachter']:hover {
		opacity: ${(props) => !props.disabled && '0.5'};
	}

	svg {
		width: 100%;
		height: 100%;
		&.child {
			height: 85%;
			#haarachter {
				opacity: 0;
			}
		}
	}

	path[data-selected],
	g[data-selected] path,
	g[data-selected] path[id='haar'],
	g[data-selected] path[id='haarachter'] {
		opacity: 0.6;
	}
`;

const Switch = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	background-color: ${(props) => props.theme.colors.text200};
	border-radius: ${(props) => props.theme.radii.default};
	padding: 4px;
	margin-bottom: ${(props) => props.theme.spacings.small};

	& span {
		text-align: center;
		flex-grow: 1;
		cursor: pointer;
		border-radius: ${(props) => props.theme.radii.default};
		padding: 4px;
		font-size: ${(props) => props.theme.fontSizes.base};
		color: ${(props) => props.theme.colors.text600};

		&.active {
			background-color: white;
			color: ${(props) => props.theme.colors.text900};
			border: 1px solid ${(props) => props.theme.colors.borderLight};
		}
	}

	@media ${(props) => props.theme.screens.medium} {
		width: 70%;
		margin: 0 auto;
	}
`;

const SvgLoader = styled.div`
	/* max-width: 250px; */

	width: 100%;
	max-height: 500px;
	height: 60vh;
	padding-inline-end: 1em;
	display: flex;
	align-items: center;
	justify-content: center;
`;

const ComplaintsTitle = styled.h3`
	font-size: 16px;
	font-weight: 400;
	line-height: 1.5;
	width: 100%;
	color: ${(props) => props.theme.colors.text};
	text-transform: uppercase;
	display: flex;
	align-items: center;
	white-space: nowrap;
	margin-inline-start: calc(0.35 * ${(props) => props.theme.spacings.medium});
	margin-bottom: 0;
	&:after {
		content: ' ';
		display: inline-block;
		height: 1px;
		background-color: ${(props) => props.theme.colors.text};
		width: 100%;
		margin-inline-start: 7px;
	}
`;

export default {
	ActivityBubble,
	ActivityComplaintWrapper,
	Body,
	Switch,
	SvgLoader,
	ComplaintsTitle
};
