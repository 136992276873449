import styled, { DefaultTheme } from 'styled-components';
import { FocusOn } from 'react-focus-on';

export type DialogPosition = 'center' | 'top-left' | 'top' | 'top-right' | 'right' | 'bottom-right' | 'bottom' | 'bottom-left' | 'left';

interface BackdropProps {
	$visible: boolean;
}

interface FocusOnProps {
	$position: DialogPosition;
}

interface DialogProps {
	$position: DialogPosition;
}

interface ButtonsProps {
	$horizontal?: boolean;
}

interface ButtonProps {
	$secondary?: boolean;
	$danger?: boolean;
}

const dialogJustifyPositions = {
	center: 'center',
	'top-left': 'flex-start',
	top: 'flex-start',
	'top-right': 'flex-start',
	right: 'center',
	'bottom-right': 'flex-end',
	bottom: 'flex-end',
	'bottom-left': 'flex-end',
	left: 'center'
};
const dialogAlignPositions = {
	center: 'center',
	'top-left': 'flex-start',
	top: 'center',
	'top-right': 'flex-end',
	right: 'flex-end',
	'bottom-right': 'flex-end',
	bottom: 'center',
	'bottom-left': 'flex-start',
	left: 'flex-start'
};

const getButtonBackgroundColor = (buttonProps: ButtonProps, theme: DefaultTheme, pseudo?: 'hover') => {
	if (buttonProps.$secondary) {
		return pseudo === 'hover' ? theme.colors.accent200 : theme.colors.accent100;
	}
	if (buttonProps.$danger) {
		return pseudo === 'hover' ? theme.colors.errorDarker : theme.colors.error;
	}
	return pseudo === 'hover' ? theme.colors.accent600 : theme.colors.accent;
};

const getButtonColor = (buttonProps: ButtonProps, theme: DefaultTheme) => {
	if (buttonProps.$secondary) {
		return theme.colors.text700;
	}
	return theme.colors.text100;
};

const S = {
	Backdrop: styled.div<BackdropProps>`
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: rgba(0, 0, 0, 0.7);
		display: ${(props) => (props.$visible ? 'flex' : 'none')};
		flex-direction: column;
		z-index: 1500;
	`,
	FocusOn: styled(FocusOn)<FocusOnProps>`
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: ${(props) => dialogJustifyPositions[props.$position]};
	`,
	Dialog: styled.div<DialogProps>`
		width: 90%;
		border-radius: ${(props) => props.theme.radii.default};
		padding: ${(props) => props.theme.spacings.smallMedium} ${(props) => props.theme.spacings.medium};
		background-color: #fff;
		color: ${(props) => props.theme.colors.text900};
		text-align: left;
		display: flex;
		flex-direction: column;
		gap: ${(props) => props.theme.spacings.medium};
		align-self: ${(props) => dialogAlignPositions[props.$position]};
		margin: ${(props) => props.theme.spacings.medium};

		@media ${(props) => props.theme.screens.medium} {
			width: 60%;
		}

		@media ${(props) => props.theme.screens.large} {
			width: 40%;
		}
	`,
	Header: styled.h4`
		font-size: ${(props) => props.theme.fontSizes.xl};
		margin: 0;
	`,
	Content: styled.div``,
	Buttons: styled.div<ButtonsProps>`
		display: flex;
		flex-direction: ${(props) => (props.$horizontal ? 'row' : 'column')};
		gap: ${(props) => props.theme.spacings.small};
	`,
	Button: styled.button<ButtonProps>`
		/* width: 100%; */
		flex: 1;
		border-radius: ${(props) => props.theme.radii.default};
		background-color: ${(props) => getButtonBackgroundColor(props, props.theme)};
		color: ${(props) => getButtonColor(props, props.theme)};
		border: ${(props) => (props.$secondary ? `solid 1px ${props.theme.colors.accent300}` : 'none')};
		padding: ${(props) => props.theme.spacings.smallMedium};
		line-height: 1.25;
		font-size: ${(props) => props.theme.fontSizes.base};
		font-weight: 600;
		cursor: ${(props) => props.disabled ? 'auto' : 'pointer'};

		&:hover {
			background-color: ${(props) => getButtonBackgroundColor(props, props.theme, 'hover')};
			color: ${(props) => getButtonColor(props, props.theme)};
			border: ${(props) => (props.$secondary ? `solid 1px ${props.theme.colors.accent300}` : 'none')};
		}
	`
};

export default S;
