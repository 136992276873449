import { faThumbsDown, faThumbsUp } from '@fortawesome/free-regular-svg-icons';
import React, { FunctionComponent, useState } from 'react';
import S from './Feedback.styled';

interface FeedbackProps {
	title: string | JSX.Element;
	onChange?: (newValue: boolean) => void;
}

const Feedback: FunctionComponent<FeedbackProps> = ({ title, onChange }) => {
	const [state, setState] = useState<boolean | null>(null);

	const handleClick = (newValue: boolean) => {
		setState(newValue);
		onChange?.(newValue);
	}

	return (
		<S.Wrapper>
			<S.Label>{title}</S.Label>
			<S.Buttons>
				<S.Button
					icon={faThumbsUp}
					className={`positive${state ? ' active' : ''}`}
					onClick={() => handleClick(true)}
				/>
				<S.Button
					icon={faThumbsDown}
					className={`negative${state === false ? ' active' : ''}`}
					onClick={() => handleClick(false)}
				/>
			</S.Buttons>
		</S.Wrapper>
	);
};

export default Feedback;
