import React from 'react';
import styled from 'styled-components';
import { FocusOn as InternalFocusOn } from 'react-focus-on';
import type { DivProps } from '../../models/widget/Styled';

interface FocusOnProps {
	$mode?: string;
}

interface NonModalProps {
	$state?: string;
	$widgetTypeButton?: boolean;
}

interface ModalProps {
	$state?: string;
	$mode?: string;
}

const BaseModal = styled.div`
	&&& {
		--document-height: 100dvh;
		position: fixed;
		width: 100vw;
		// 100vh hides previous/next under bottom of screen on mobile
		// fallback of 100% also works
		height: var(--document-height, 100%);
		z-index: 99999999;
		top: 0;
		inset-inline-start: 0;
		display: flex;
		justify-content: center;
		align-items: center;
	}
`;

const Modal = styled(BaseModal).attrs((props) => ({ dir: props.theme.isRtl ? 'rtl' : 'ltr' }))<ModalProps>`
	&&& {
		transition: opacity 0.5s;
		opacity: ${({ $state }) => ($state === 'entered' || $state === 'exited' ? 1 : 0)};
		background: ${(props) => (props.$mode === 'dialog' ? 'rgba(0, 0, 0, 0.5)' : '#fff')};
		box-sizing: border-box;

		* {
			box-sizing: border-box;
		}
	}
`;

const FocusOn = styled(InternalFocusOn)<FocusOnProps>`
	position: relative;
	display: flex;
	justify-content: center;
	&&& {
		margin: 0;
		width: 100%;
		height: 100%;
		max-width: ${(props) => (props.$mode === 'dialog' ? `${props.theme.sizes.maxWidth}px` : '100%')};
		max-height: ${(props) => (props.$mode === 'dialog' ? `${props.theme.sizes.maxHeight}px` : '100%')};
		@media ${(props) => props.theme.screens.medium} {
			margin: ${(props) => (props.$mode === 'dialog' ? '20px' : '0')};
		}
	}
`;

const NonModal = styled.div<NonModalProps>`
	&&& {
		transition: opacity 1.5s;
		opacity: ${({ $state }) => ($state === 'entered' || $state === 'exited' ? 1 : 0)};
		height: 100%;
		display: inline-block;
		width: 100%;

		${({ $widgetTypeButton, theme }) =>
			!$widgetTypeButton &&
			`
				border-radius: ${theme.radii.default};
				background: linear-gradient(180deg, ${theme.colors.accent200} 0%, #fff 100%);
			`}
	}
`;

const ModalInner = styled.div<FocusOnProps>`
	&&& {
		background-color: #fff;
		box-shadow: ${(props) => (props.$mode === 'dialog' ? '0 0 40px 0px rgba(0,0,0,0.1)' : 'none')};
		margin: 0;
		width: 100%;
		height: 100%;

		@media ${(props) => props.theme.screens.medium} {
			border-radius: ${(props) => props.theme.radii.default};
			// otherwise the bottom right border-radius isn't visible due to the vertical scroll bar. Also provides a clearer separation modal / non-modal
			padding-bottom: ${(props) => props.theme.radii.default};
		}
	}
`;

export default {
	BaseModal: (props: DivProps) => (
		<BaseModal {...props} data-minddclass="basemodal">
			{props.children}
		</BaseModal>
	),
	Modal: React.forwardRef((props: DivProps<ModalProps>, ref: React.ForwardedRef<HTMLDivElement>) => (
		<Modal {...props} data-minddclass="modal" ref={ref}>
			{props.children}
		</Modal>
	)),
	FocusOn,
	NonModal: React.forwardRef((props: DivProps<NonModalProps>, ref: React.ForwardedRef<HTMLDivElement>) => (
		<NonModal {...props} ref={ref} data-minddclass="nonmodal">
			{props.children}
		</NonModal>
	)),
	ModalInner: (props: DivProps<FocusOnProps>) => (
		<ModalInner {...props} data-minddclass="modalinner">
			{props.children}
		</ModalInner>
	)
};
